import React from 'react'
import Map from '../../Contact/Map'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import HerosectionL7 from '../../../Componet/Herosection/HerosectionL7'
import { Helmet } from 'react-helmet';
import  Pricing  from '../../../Componet/Pricing/Pricing';
import Pricing1 from '../../../Componet/Pricing/Pricing1';
import Addons from '../../../Componet/Pricing/Addons';
import BlogL5 from '../../../Componet/Blog/BlogL5';





function Contact1() {
    return (
        <>  
        <Helmet>
            <title>Pricing | DNY Practice</title>
            <meta name="description" content="Get in touch with our expert team for personalized accounting and tax solutions. We're here to help you maximize your financial potential. Contact us today." />
            <meta name="keywords" content="Pricing, Accounting, Tax, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/pricing" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Contact us for Expert Accounting & Tax Services" />
            <meta property="og:description" content="Personalized solutions to maximize your financial potential." />
            <meta property="og:url" content="https://dnypractice.com.au/pricing" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionL7 />
            <div class="page-content">
                
                <Pricing />
                <Pricing1 />
                <div className="table-container">
                <Addons />
                </div>
                <NewsletterL1/>
                <BlogL5 />

            </div>
        </>
    )
}

export default Contact1
