import React from 'react';

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    fontSize: '1.5em'
  };

  return (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', zIndex: 1000, borderRadius: '8px', border: '1px solid #ccc' }}>
      <span style={closeButtonStyle} onClick={onClose}>&times;</span>
      {children}
    </div>
  );
};

export default Modal;











{/*const Modal = ({ show, onClose, children }) => {
    if (!show) return null;
  
    return (
        <div style={{ 
          position: 'fixed', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          backgroundColor: '#fff', 
          padding: '20px', 
          zIndex: 1000,
          border: '2px solid #ccc',  // Add this line for the border
          borderRadius: '8px'  // Add this line for rounded corners
        }}>
          {children}
          <button onClick={onClose}>Close</button>
        </div>
      );
    };
    
  export default Modal;*/}
  