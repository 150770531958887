import React from 'react';
import './calculator.css';

const CalculatorButtons = () => {
  const calculators = [
    {
      name: 'Tax Calculator 2023',
      link: '/calculator/taxcalculator',
      description: 'A tax calculator for individuals is available for the 2022-23 period to help estimate tax obligations based on income and deductions.',
      target: '_blank'
    },
    {
      name: 'Car Loan Repayment',
      link: '/calculator/car',
      description: 'A car loan repayment calculator with a balloon payment option is available to help estimate monthly payments and total interest.',
      target: '_blank'
    },
    {
      name: 'Negative Gearing',
      link: '/calculator/negativegearing',
      description: 'A negative gearing calculator can help assess potential tax benefits and losses of an investment property with mortgage interest expenses exceeding rental income.'
    },
   
    
    {
      name: 'Return on Equity',
      link: '/calculator/equity',
      description: 'This calculator helps Australian investors estimate the Return on equity on their property investment.'
    }, 
   
    {
      name: 'Franking Credit Calculator',
      link: 'calculator/frankingcredit',
      description: 'A franking credit calculator can help estimate the value of franking credits from dividends.'
    },

    {
      name: 'Novated Lease',
      link: 'calculator/fbt',
      description: 'Calculator to compare and estimate the tax benefits of a novated lease for EVS and ICE vehicles.'
    },

    {
      name: 'Compound Interest Calculator',
      link: 'calculator/compoundingcalculator',
      description: 'Calculator to calculate Amount at maturity with monthly payments and starting principal.'
    },
    {
      name: 'Mortgage Calculator',
      link: 'calculator/mortgage',
      description: 'Calculator to calculate repayments on a mortgage with monthly payments'
    },
    {
      name: 'Stage 3 Tax cuts',
      link: 'calculator/stage3tax',
      description: 'Calculator to calculate impact on income with stage 3 tax cuts'
    }
   
  ];

  return (
    <div className="calculator-buttons" style={{ padding: '0 15%' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
        {calculators.map((calculator, index) => (
          <div key={index} className="calculator-item">
            <a href={calculator.link} target="_blank" rel="noopener noreferrer">
              <button className="btn btn-primary">{calculator.name}</button>
            </a>


            <p className="calculator-description">{calculator.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default CalculatorButtons;
