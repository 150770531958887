import React from 'react'

function FeatureL1() {
  const gototop=()=>{
    window.scrollTo({top:0,behavior:"smooth"})
}
    return (
      <>
         <section>
          <div class="container">
            <div
              class="row justify-content-between align-items-center mb-4 mb-lg-0"
            >
              <div class="col-lg-6 col-md-5">
                <div>
                <h2>
                  <span 
                      className="font-w-4 d-block" 
                      style={{ whiteSpace: 'nowrap', fontSize: 'calc(1em + 0.1vw)' }}
                       >
                      Your Business, Our Priority
                     </span>
                     <span 
                      style={{ 
                    display: 'block', // This makes the span behave like a block element
                    fontSize: 'calc(1em + 0.1vw)', // Set the base font size
                    fontWeight: 'bold', // Adjust font weight as needed
                  // Add any additional styling you require here
                        }}
                         >

                      Expert Solutions for Your Accounting Needs 
                      </span>

                </h2>
                  <p class="lead">
                  Leveraging cutting-edge technology to deliver accurate, efficient, and personalized accounting services.

                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-7">
                <div class="p-5 feature-hover active position-relative">
                  <div class="f-icon"><i class="flaticon-prototype"></i></div>
                  {/* <img src={require("../../../../assets/icon/feedback.png")} className='cimg' /> */}
                  <h5 class="mt-4 mb-3">Tax Planning & Compliance</h5>
                  <p class="mb-0">
                  Expert advice and solutions for all your tax planning and compliance needs.
                  </p>
                </div>
              </div>
            </div>
            <div class="row mt-lg-n5 align-items-center">
              <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div class="p-5 feature-hover position-relative">
                  <div class="f-icon"><i class="flaticon-knowledge"></i></div>
                  <h5 class="mt-4 mb-3">Business Advisory</h5>
                  <p class="mb-0">
                  Tailored business advisory services to help you achieve your financial goals.

                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div class="p-5 feature-hover position-relative">
                  <div class="f-icon"><i class="flaticon-thumbs-up"></i></div>
                  <h5 class="mt-4 mb-3">Financial Analysis</h5>
                  <p class="mb-0">
                  In-depth financial analysis to help you make informed business decisions.

                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-12 text-center">
              <a href="/services" class="btn btn-primary mt-lg-8">
                View All Services
              </a>

              </div>
            </div>
          </div>
        </section>
      </>
    )
}

export default FeatureL1
