const Equity1 = () => {
  const iframeStyle = {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
    border: 'none',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  };

  const columnStyle = {
    flex: 1,
    padding: '0 20px',
  };

  return (
    <div style={containerStyle}>
      <div style={columnStyle}>
        <div style={{ textAlign: 'center', paddingBottom: 20 }}>
          <h4 style={{ paddingBottom: '40px' }}>Novated Leasing Calculator</h4>
          <h6 style={{ paddingBottom: '40px' }}>
            Please enter the figures in Yellow and you can{' '}
            <a href="https://blog.dnypractice.com.au/Novated%20Leasing/">read this article for more clarity</a>
            </h6>

          <iframe 
            style={iframeStyle}
            width="599" 
            height="526" 
            frameBorder="0" 
            scrolling="no" 
            src="https://onedrive.live.com/embed?resid=5E195D0B1DF2837F%2114592&authkey=%21AM2D1YaY0UnMmMY&em=2&wdAllowInteractivity=False&AllowTyping=True&Item=main&wdHideGridlines=True&wdInConfigurator=True&wdInConfigurator=True">
          </iframe>
        </div>
        <div style={{ maxWidth: '450px', textAlign: 'center', paddingTop: '20px', backgroundColor: '#f8f8f8', border: '1px solid #ddd', margin: 'auto', padding: '15px', fontSize: '16px', color: '#666', lineHeight: '1.5' }}>
             <p><strong>Disclaimer:</strong> The information provided by this calculator is for illustrative purposes only. Be sure to consult a financial professional prior to relying on the results.</p>
            </div>
      </div>
      
    </div>
  );
};

export default Equity1;


