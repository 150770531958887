import React from 'react'
import Map from '../../Contact/Map'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import HerosectionL5 from '../../../Componet/Herosection/HerosectionL5'
import HerosectionL2 from '../../../Componet/Herosection/HerosectionL2'
import { Helmet } from 'react-helmet';




function Contact1() {
    return (
        <>
        <Helmet>
            <title>Contact Us for Expert Accounting & Tax Services | DNY Practice</title>
            <meta name="description" content="Get in touch with our expert team for personalized accounting and tax solutions. We're here to help you maximize your financial potential. Contact us today." />
            <meta name="keywords" content="Contact, Accounting, Tax, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/contact" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Contact Us for Expert Accounting & Tax Services" />
            <meta property="og:description" content="Personalized solutions to maximize your financial potential." />
            <meta property="og:url" content="https://dnypractice.com.au/contact" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionHeader name={"Contact Us"}/>
            <div class="page-content">
                <Map />
                <NewsletterL1 />
            </div>
        </>
    )
}

export default Contact1
