import React, { useEffect, useState } from 'react'
import "./Vender"
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Landing1 from './Pages/Home/Landing1/Landing1'
import Header1 from './Layout/Header/Header1'
import Footer1 from './Layout/Footer/Footer1'
import AboutUs from './Pages/Company/AboutUs'
import Header2 from './Layout/Header/Header2';
import Doctor from './Pages/Home/Landing1/Doctor.js';
import Freelancer from './Pages/Home/Landing1/Freelancer.js';
import Calculator from './Pages/Home/Landing1/Calculator.js';
import FAQ from './Pages/Utilities/FaqPage';
import Maintenance from './Pages/Utilities/MaintanancePage';
import Error404 from './Pages/Utilities/Error404.js';
import Error404WithoutHeaderFooter from './Componet/NotDefinedPage';
import Car from './Pages/Home/Landing1/Car';
{/*import Equity from './Pages/Home/Landing1/Equity'; */}
import NotFoundPage from './Pages/Home/Landing1/NotFoundPage.js';
import TagManager from 'react-gtm-module';
import Services from './Pages/Home/Landing1/Services';
import BookkeepingPage from './Pages/Home/Landing1/BookkeepingPage.js';

import Contact from './Pages/Home/Landing1/Contact';
/*import LexWebUI from './Componet/Chatbot/LexWebUI';*/
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Routes, Outlet } from 'react-router-dom';
import CapitalGrowth from './Pages/Home/Landing1/CapitalGrowth';
import Popup from './Componet/Popup/Popup';

import NegativeGearing from './Pages/Home/Landing1/NegativeGearing';
import TaxCalculator from './Pages/Home/Landing1/TaxCalculator';
import CapitalGainsTaxCalculator from './Componet/Blog/Calculators/CapitalGainsTaxCalculator';
import EquityCalculator from './Pages/Home/Landing1/EquityCalculator';
import FrankingCredit from './Pages/Home/Landing1/FrankingCredit';
import FBT from './Pages/Home/Landing1/FBT';
import CaseStudy from './Pages/Blog/CaseStudy';
import Success from './Pages/Home/Landing1/SuccessPage';
import CaseStudy1 from './Pages/Blog/CaseStudy1';
import Chatbot from './Componet/Chatbot/Chatbot';
import CompoundingCalculator from './Pages/Home/Landing1/CompoundingCalculator.js';
import MortgageCalculator from './Pages/Home/Landing1/MortgageCalculator.js';
import Pricing from './Pages/Home/Landing1/Pricing.js';
import Calendar from './Pages/Home/Landing1/Calendar.js';
import ClosureBanner from './Componet/Holidays/ClosureBanner.js';
import Stage3Tax from './Pages/Home/Landing1/Stage3Tax.js';



const tagManagerArgs = {
  gtmId: 'GTM-NZX7H266'
};

TagManager.initialize(tagManagerArgs);

global.Buffer = global.Buffer || require('buffer').Buffer;


function App() {
  global.Buffer = global.Buffer || require('buffer').Buffer;
  const [lexVisible, setLexVisible] = useState(false);

  const toggleLexUI = () => {  // Defined within the same functional component
    setLexVisible(!lexVisible);
  };

  const location = useLocation();
  const getUrl = (pathname) => {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/coming_soon'
      ? true
      : `/${pathArray[1]}` === '/maintenance'
        ? true
        : `/${pathArray[1]}` === '/error_404'
          ? true
          : `/${pathArray[1]}` === '*'
            ? true
            : false;
  };

  const setHeader = (pathname) => {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/index4' ? true : false;
  }

  const setFooter = (pathname) => {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/index10' ? false : true;
  };
  const scrollbarStyle = `
  ::-webkit-scrollbar {
    display: none;
  }`;

  useEffect(() => {
    const handlePopstate = () => {
      window.location.reload(); // Refresh the page on popstate event (back button)
    };

    // window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [location.pathname]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {getUrl(location.pathname) ?
        <Routes>
          <Route path="/error_404" element={<Error404 />} />
          <Route path="/maintenance" element={<Maintenance />} />
          <Route path="*" element={<Outlet />}>
            {/* Render the Error404 component without Header and Footer */}
          <Route index element={<Error404WithoutHeaderFooter />} />
          </Route>
        </Routes> :

        <div className="page-wrapper">
          {setHeader(location.pathname) ? <Header2 /> : <Header1 />}
          <Routes>
            {/* NavbarHome */}
            <Route path='/' element={<Landing1 />} />
            <Route path='/medicalpros' element={<Doctor />} />
            <Route path='/freelancer' element={<Freelancer />} />

            {/* NavbarHome */}
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/calculator' element={<Calculator />} />
            <Route path='/calculator/car' element={<Car />} />
            <Route path='/calculator/capitalgrowth' element={<CapitalGrowth />} />
            <Route path='/calculator/negativegearing' element={<NegativeGearing />} />
            <Route path='/calculator/taxcalculator' element={<TaxCalculator />} />
            <Route path='/calculator/capitalgainstaxcalculator' element={<CapitalGainsTaxCalculator />} />
            <Route path='/calculator/compoundingcalculator' element={<CompoundingCalculator />} />
            <Route path='/services' element={<Services />} />
            <Route path='/services/bookkeeping' element={<BookkeepingPage />}/>
            
            <Route path='/faq' element={<FAQ />} />
            <Route path='/calculator/equity' element={<EquityCalculator />} />
            <Route path='/calculator/frankingcredit' element={<FrankingCredit />} />
            <Route path='/calculator/fbt' element={<FBT />} />
            <Route path='/calculator/mortgage' element={<MortgageCalculator />} />

            
            <Route path='/contact' element={<Contact />} />
            <Route path='/casestudies/salon' element={<CaseStudy />} />
            <Route path='/casestudies/freelancer' element={<CaseStudy1 />} />

            <Route path ='/success' element={<Success />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/calendar' element={<Calendar />} />
            <Route path='calculator/stage3tax' element={<Stage3Tax />} />






            
            {/* {blogs.map((blog, index) => (
           <Route path={`/blogs/${blog.name}`} element={<BlogPage />} />
            ))}
            {blogs.map((blog, index) => (
              <Route path="/blogs/:blogName" element={<BlogPage />} />
            ))}
            <Route path="/blogs" element={<AllBlogs />} />
            */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Popup />
          <ClosureBanner />
          <Footer1 />
         { /* <Chatbot /> */}
          
        </div>
      }
    </>
  )
}

export default App;


/*
          <LexWebUI />

          */