import React from 'react'

function BlogL4() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row align-items-end mb-5">
                        <div className="col-12 col-md-12 col-lg-4">
                            <div>
                                <h2 className="mb-0">
                                    <span className="font-w-4 d-block">From Our Blog</span>
                                    
                                </h2>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-5 ps-lg-5 my-3 my-lg-0">
                            
                        </div>
                        <div className="col-12 col-md-12 col-lg-3 text-lg-end">
                            <a href="/blogs/" className="btn btn-primary">
                                View More Post
                            </a>
                        </div>
                    </div>
                    {/* <!-- / .row --> */}
                    <div className="row">
                        <div className="col-md-6 col-lg-4 mb-5 mb-lg-0">
                            {/* <!-- Blog Card --> */}
                            <div className="card border-0 shadow bg-transparent">
                                <img
                                    className="card-img-top"
                                    src={require("../../assets/images/blog/01.jpg")}
                                    alt="Image1"
                                />
                                <div className="card-body p-4">
                                    <div>
                                        <div
                                            className="d-inline-block bg-light text-center px-2 py-1 rounded me-2"
                                        >
                                            <span className="text-primary">05</span> Sep
                                        </div>
                                        <a className="d-inline-block btn-link" href="/">Sass</a>
                                    </div>
                                    <h2 className="h5 my-3">
                                        <a className="link-title" href="https://blog.dnypractice.com.au/The%20Importance%20of%20Personal%20Profit%20Loss/"
                                        >Importance of Personal Profit & Loss</a>
                                    </h2>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item pe-3">
                                            <a href="/" className="list-group-item-action"
                                            ><i
                                                className="lar la-user-circle me-1 text-primary ic-1x"
                                            ></i>
                                                Admin</a>
                                        </li>
                                        <li className="list-inline-item pe-3">
                                            <a href="/" className="list-group-item-action"
                                            ><i className="las la-eye me-1 text-primary ic-1x"></i>
                                                275</a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="/" className="list-group-item-action"
                                            ><i
                                                className="lar la-comments me-1 text-primary ic-1x"
                                            ></i>
                                                300</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- End Blog Card --> */}
                        </div>
                        <div className="col-md-6 col-lg-4 mb-5 mb-lg-0">
                            {/* <!-- Blog Card --> */}
                            <div className="card border-0 shadow bg-transparent">
                                <img
                                    className="card-img-top"
                                    src={require("../../assets/images/blog/03.jpg")}
                                    alt="Image2"
                                />
                                <div className="card-body p-4">
                                    <div>
                                        <div
                                            className="d-inline-block bg-light text-center px-2 py-1 rounded me-2"
                                        >
                                            <span className="text-primary">05</span> Sep
                                        </div>
                                        <a className="d-inline-block btn-link" href="/">Tax</a>
                                    </div>
                                    <h2 className="h5 my-3">
                                        <a className="link-title" href="https://blog.dnypractice.com.au/Personal%20Services%20Income/"
                                        >Personal Services Income</a>
                                    </h2>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item pe-3">
                                            <a href="/" className="list-group-item-action"
                                            ><i
                                                className="lar la-user-circle me-1 text-primary ic-1x"
                                            ></i>
                                                Admin</a>
                                        </li>
                                        <li className="list-inline-item pe-3">
                                            <a href="/" className="list-group-item-action"
                                            ><i className="las la-eye me-1 text-primary ic-1x"></i>
                                                275</a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="/" className="list-group-item-action"
                                            ><i
                                                className="lar la-comments me-1 text-primary ic-1x"
                                            ></i>
                                                300</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- End Blog Card --> */}
                        </div>
                        <div className="col-md-6 col-lg-4">
                            {/* <!-- Blog Card --> */}
                            <div className="card border-0 shadow bg-transparent">
                                <img
                                    className="card-img-top"
                                    src={require("../../assets/images/blog/02.jpg")}
                                    alt="Image3"
                                />
                                <div className="card-body p-4">
                                    <div>
                                        <div
                                            className="d-inline-block bg-light text-center px-2 py-1 rounded me-2"
                                        >
                                            <span className="text-primary">05</span> Sep
                                        </div>
                                        <a className="d-inline-block btn-link" href="/">Property</a>
                                    </div>
                                    <h2 className="h5 my-3">
                                        <a className="link-title" href="https://blog.dnypractice.com.au/Negative%20Gearing%20in%20Australia/"
                                        >Negative Gearing in Australia: Is It Worth the Risk for Property Investors?</a>
                                    </h2>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item pe-3">
                                            <a href="/" className="list-group-item-action"
                                            ><i
                                                className="lar la-user-circle me-1 text-primary ic-1x"
                                            ></i>
                                                Admin</a>
                                        </li>
                                        <li className="list-inline-item pe-3">
                                            <a href="/" className="list-group-item-action"
                                            ><i className="las la-eye me-1 text-primary ic-1x"></i>
                                                275</a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="/" className="list-group-item-action"
                                            ><i
                                                className="lar la-comments me-1 text-primary ic-1x"
                                            ></i>
                                                300</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- End Blog Card --> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogL4
