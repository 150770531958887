import React from 'react';
import './WhatIsBookkeeping.css';
import localImage from '../../../src/assets/images/blog/01.webp'; // adjust the path as needed

const BookkeepingComponent = () => {
  return (
    <div className="bookkeeping-container">
      <div className="content-section">
        <div className="image-section">
          <img src={localImage} alt="Description of image" /> {/* use the imported image here */}
        </div>
        <div className="text-section">
          <h3 className="header">What is Bookkeeping?</h3>
          <p className="additional-content">
            Bookkeeping is the process of recording and organizing all financial transactions in a business. It involves maintaining accurate and up-to-date records of sales, purchases, payments, and receipts.
          </p>
          <div className="additional-content">
            <p>
              This systematic record-keeping provides the data needed to prepare financial statements, such as the income statement and balance sheet, which are essential for assessing the financial health of a business. Bookkeeping is a foundational aspect of accounting, and it's crucial for ensuring compliance with tax laws and for making informed business decisions.
            </p>
            <p>
              <strong>Do I need a Bookkeeper?</strong>
              <br />
              The necessity of a bookkeeper for your business depends on your transaction volume and complexity, time spent on bookkeeping, and need for accurate financial reports. A bookkeeper ensures tax compliance and offers insights for decision-making, allowing you to concentrate on business growth. If managing financial records overwhelms you or you seek better time utilization, our bookkeeping services can streamline your finances and propel your business.</p>
            <p>
            Ready to take the next step?
            </p>
            <a href="/calendar" className="btn btn-primary">Schedule a call</a>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BookkeepingComponent;


{/*import React, { useState } from 'react';
import './WhatIsBookkeeping.css';
import localImage from '../../../src/assets/images/team/03.jpg'; // adjust the path as needed


const BookkeepingComponent = () => {
  const [readMore, setReadMore] = useState(false);

  const handleReadMoreClick = () => {
    setReadMore(!readMore);
  };

  return (
    <div className="bookkeeping-container">
      <div className="content-section">
        <div className="image-section">
        <div className="image-placeholder">
          <img src={localImage} alt="Description of image" /> 
        </div>
        </div>
        <div className="text-section">
          <h1 className="header">What is Bookkeeping?</h1>
          <p className="additional-content">
            Bookkeeping is the process of recording and organizing all financial transactions in a business. It involves maintaining accurate and up-to-date records of sales, purchases, payments, and receipts.
          </p>
          {readMore && (
            <div className="additional-content">
              <p>
                This systematic record-keeping provides the data needed to prepare financial statements, such as the income statement and balance sheet, which are essential for assessing the financial health of a business. Bookkeeping is a foundational aspect of accounting, and it's crucial for ensuring compliance with tax laws and for making informed business decisions.
              </p>
              <p>
                <strong>Do I need a Bookkeeper?</strong>
                <br />
                Deciding whether you need a bookkeeper for your business hinges on several key aspects. Consider the volume and complexity of your financial transactions, the time you spend on bookkeeping tasks, and your need for accurate financial reporting. A professional bookkeeper not only ensures compliance with tax laws but also provides valuable insights for decision-making, freeing up your time to focus on growing your business. If you find yourself overwhelmed by financial record-keeping or seeking more strategic use of your time, our bookkeeping services are here to help streamline your finances and drive your business forward.
              </p>
            </div>
          )}
          <span onClick={handleReadMoreClick} className="read-more-text">
            {readMore ? 'Read less' : 'Read more'}
          </span>
        </div>
      </div>
    </div>
  );
}

export default BookkeepingComponent;*/}




{/*
import React, { useState } from 'react';
import './WhatIsBookkeeping.css';

const BookkeepingComponent = () => {
  const [readMore, setReadMore] = useState(false);

  const handleReadMoreClick = () => {
    setReadMore(!readMore); // This will toggle the readMore state between true and false
  };

  return (
    <div className="bookkeeping-container">
      <div className="text-section">
        <h1 className="header">What is Bookkeeping?</h1>
        <p className="additional-content">
        Bookkeeping is the process of recording and organizing all financial transactions in a business. It involves maintaining accurate and up-to-date records of sales, purchases, payments, and receipts.
        </p>
        {readMore && (
          <div className="additional-content">
            <p>
            This systematic record-keeping provides the data needed to prepare financial statements, such as the income statement and balance sheet, which are essential for assessing the financial health of a business. Bookkeeping is a foundational aspect of accounting, and it's crucial for ensuring compliance with tax laws and for making informed business decisions.
            </p>
            <p>
              <strong>Do I need a Bookkeeper?</strong>
              <br />
              Deciding whether you need a bookkeeper for your business hinges on several key aspects. Consider the volume and complexity of your financial transactions, the time you spend on bookkeeping tasks, and your need for accurate financial reporting. A professional bookkeeper not only ensures compliance with tax laws but also provides valuable insights for decision-making, freeing up your time to focus on growing your business. If you find yourself overwhelmed by financial record-keeping or seeking more strategic use of your time, our bookkeeping services are here to help streamline your finances and drive your business forward.
            </p>
          </div>
        )}
        <span onClick={handleReadMoreClick} className="read-more-text">
          {readMore ? 'Read less' : 'Read more'}
        </span>
      </div>
      <div className="image-section">
        <div className="image-placeholder"></div>
      </div>
    </div>
  );
}

export default BookkeepingComponent; */}
