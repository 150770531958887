import React from 'react'
import SuccessPage from '../../../Componet/SuccessPage/SuccessPage'
import HerosectionL3 from '../../../Componet/Herosection/HerosectionL3'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import FrankingCredit from '../../../Componet/Blog/Calculators/FrankingCredit'
import { Helmet } from 'react-helmet'

function Success() {
    return (
        <>
        <Helmet>
            <title>Thanks | DNY Practice</title>
            <meta name="description" content="Success" />
            <meta name="keywords" content="Free Business Consultation" />
            <link rel="canonical" href="https://dnypractice.com.au/success" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Success page" />
            <meta property="og:description" content="Success page" />
            <meta property="og:url" content="https://dnypractice.com.au/success" />
            <meta property="og:type" content="website" />
        </Helmet>

            <div className="page-content">
                <SuccessPage />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default Success
