import React from 'react'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import HerosectionL2 from '../../../Componet/Herosection/HerosectionL2'

import BlogL5 from '../../../Componet/Blog/BlogL5'
import Equity from '../../../Componet/Blog/Calculators/Equity'
import Kalendar from '../../../Componet/Blog/Calculators/Calendar'
import { Helmet } from 'react-helmet'

function Calendar() {
    return (
        <>
        <Helmet>
            <title>Book Your Appointment | Professional Accounting Services | DNY Practice</title>
            <meta name="description" content="Easily book your accounting, bookkeeping, or tax consultation with DNY Practice. Schedule your appointment today to get expert financial advice tailored to your needs." />
            <meta name="keywords" content="Book Appointment, Accounting Services, Tax Consultation, Financial Advice, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calendar" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Book Your Appointment | DNY Practice" />
            <meta property="og:description" content="Schedule your professional accounting or financial consultation with ease." />
            <meta property="og:url" content="https://dnypractice.com.au/calendar" />
            <meta property="og:type" content="website" />
        </Helmet>


            <HerosectionHeader name={"Book a Free Chat"}/>
            <div className="page-content">
                <Kalendar/>

                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default Calendar
