import React, { useState } from 'react';
import './MortgageRepaymentCalculator.css';

const MortgageRepaymentCalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [term, setTerm] = useState('');
  const [monthlyRepayment, setMonthlyRepayment] = useState(null);

  const calculateMortgageRepayment = () => {
    const principal = parseFloat(loanAmount);
    const monthlyInterestRate = parseFloat(interestRate) / 100 / 12;
    const totalPayments = parseFloat(term) * 12;

    const monthlyPayment = principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments) / (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

    setMonthlyRepayment(monthlyPayment.toFixed(2));
  };

  return (
    <div className="calculator-container text-centre">
      <h3>Mortgage Repayment Calculator</h3>
      <form className="calculator-form">
        <label>
          Loan Amount ($):
          <input type="number" value={loanAmount} onChange={(e) => setLoanAmount(e.target.value)} />
        </label>
        <label>
          Annual Interest Rate (%):
          <input type="number" value={interestRate} onChange={(e) => setInterestRate(e.target.value)} />
        </label>
        <label>
          Term (years):
          <input type="number" value={term} onChange={(e) => setTerm(e.target.value)} />
        </label>
        
        <button type="button" className="btn btn-primary" onClick={calculateMortgageRepayment}>
          Calculate
        </button>
      </form>
      {monthlyRepayment && (
        <div className="monthly-repayment-container">
          <p><strong>Monthly Repayment: </strong> <span className="monthly-repayment-amount">${monthlyRepayment}</span></p>
          <div style={{ maxWidth: '450px', textAlign: 'center', paddingTop: '20px', backgroundColor: '#f8f8f8', border: '1px solid #ddd', margin: 'auto', padding: '15px', fontSize: '16px', color: '#666', lineHeight: '1.5' }}>
             <p><strong>Disclaimer:</strong> The information provided by this calculator is for illustrative purposes only. Be sure to consult a financial professional prior to relying on the results.</p>
            </div>
        </div>
      )}
    </div>
  );
};

export default MortgageRepaymentCalculator;
