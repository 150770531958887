import React from 'react'
import AboutFAQ from '../../../../src/Componet/About/AboutFaq.js'
import Bookkeepinghead from '../../../Componet/Herosection/BookkeepingHeader.js';
import WhatIsBookkeeping from '../../../Componet/Service/WhatIsBookkeeping.js';
import { Helmet } from 'react-helmet';
import Bookkeeping from '../../../Componet/Service/Bookkeeping'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import Blog from '../../../Componet/Blog/BookkeepingBlog.js'





function BookkeepingPage() {
    return (
        <>
        <Helmet>
            <title>Expert Bookkeeping Services | DNY Practice</title>
            <meta name="description" content="Discover our professional bookkeeping services, designed to support both individuals and businesses with precise financial management. Start optimizing your financial processes today." />
            <meta name="keywords" content="Bookkeeping Services, Financial Management, Business Bookkeeping, Personal Finance, Melbourne" />
            <link rel="canonical" href="https://dnypractice.com.au/services/bookkeeping" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Professional Bookkeeping Services for Effective Financial Management" />
            <meta property="og:description" content="Streamline your financial processes with our specialized bookkeeping services for both individuals and businesses." />
            <meta property="og:url" content="https://dnypractice.com.au/services/bookkeeping" />
            <meta property="og:type" content="website" />
        </Helmet>

            <Bookkeepinghead />
            <div className="page-content">
                <WhatIsBookkeeping />
                <Bookkeeping />
                <Blog />
                
                <AboutFAQ />  

                <NewsletterL1 />


                
            </div>
        </>
    )
}

export default BookkeepingPage
