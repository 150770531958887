import React from 'react'
import HerosectionL3 from '../../../Componet/Herosection/HerosectionL3'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import CarLoanCalculator from '../../../Componet/Blog/Calculators/CarLoanCalculator'
import { Helmet } from 'react-helmet'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'

function Car() {
    return (
        <>
        <Helmet>
            <title>Car Loan Calculator | Accurate & Easy-to-Use | DNY Practice</title>
            <meta name="description" content="Calculate your car loan payments with accuracy and ease. Our Car Loan Calculator helps you plan your finances better. Try it now at DNY Practice." />
            <meta name="keywords" content="Car Loan Calculator, Car Loan, Financial Planning, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calculator/car" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Car Loan Calculator | Accurate & Easy-to-Use" />
            <meta property="og:description" content="Plan your finances better with our Car Loan Calculator." />
            <meta property="og:url" content="https://dnypractice.com.au/calculator/car" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionHeader name="Car Loan Calculator"/>
            
                <CarLoanCalculator />
                <NewsletterL1 />
                <BlogL5 />
                

                
        </>
    )
}

export default Car
