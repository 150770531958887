import React from 'react'
import HerosectionL3 from '../../../Componet/Herosection/HerosectionL3'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import TaxCalculator from '../../../Componet/Blog/Calculators/TaxCalculator'
import { Helmet } from 'react-helmet'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'

function Car() {
    return (
        <>
        <Helmet>
            <title>Tax Calculator 2023 | Accurate Australian Tax Estimates | DNY Practice</title>
            <meta name="description" content="Get accurate tax estimates for 2023 with our Australian Tax Calculator. Ideal for individuals and businesses looking to plan their taxes. Try it now at DNY Practice." />
            <meta name="keywords" content="Tax Calculator 2023, Australian Tax, Tax Planning, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calculator/taxcalculator" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Tax Calculator 2023 | Accurate Australian Tax Estimates" />
            <meta property="og:description" content="Plan your taxes for 2023 with confidence using our calculator." />
            <meta property="og:url" content="https://dnypractice.com.au/calculator/taxcalculator" />
            <meta property="og:type" content="website" />
        </Helmet>


           {/* <HerosectionL3 /> */}
            
                <HerosectionHeader name="Tax Calculator 2023" />
                <TaxCalculator />

                <NewsletterL1 />
                <BlogL5 />
                

                
        </>
    )
}

export default Car
