import React from 'react';
import './PricingTable.css'; // Make sure to create a corresponding CSS file

const data = [
  {
    "plan": "Freelancer/Contractor Plan",
    "for": "Individual freelancers and contractors running their own business",
    "price": "$1,199/year",
    "features": [
      "Individual tax return",
      "Quarterly BAS lodgement",
      "Year-end tax Planning"
    ]
  },
  {
    "plan": "Starting Out Plan",
    "for": "Small businesses with minimal transactions",
    "price": "$200/month",
    "features": [
      "BAS submission",
      "Year-end tax work",
      "Annual financial statements",
      "Annual Tax Return",
      "ASIC compliance (if needed)",
      "Hand holding and support"
    ]
  },
  {
    "plan": "Basic Small Business Plan",
    "for": "Growing small businesses with less than $300k in revenue",
    "price": "$350/ month",
    "features": [
      "Bank Reconciliations (up to 50 transactions)",
      "Quarterly BAS lodgement",
      "ASIC compliance",
      "Year-end tax support",
      "Annual financial statements",
      "Annual tax return"
    ]
  }
];


const PricingTable = () => {
  return (
    <div className="pricing-table">
      {data.map((item, index) => (
        <div key={index} className="pricing-option">
          <h2>{item.plan}</h2>
          <p className="price">{item.price}</p>
          <p className="for">{item.for}</p>
          <a href="/calendar" className="btn btn-primary" target="_blank" rel="noopener noreferrer">
            Get started now
          </a>         
          <ul>
            {item.features.map((feature, featureIndex) => (
              <li key={featureIndex}>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default PricingTable;
