import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TestimonialSection = () => {
    const [activeSlide, setActiveSlide] = useState(0);

    const testimonials = [
        {
            id: 1,
            image: 'assets/images/testimonial/01.jpg',
            text: 'Switching to D & Y practice was the best business decision I made in the last year. Their team not only sorted out my messy accounts but also helped me save on taxes. Highly recommended!',
            name: 'Raymond L. ',
            role: 'Director',
        },
        {
            id: 2,
            image: 'assets/images/testimonial/02.jpg',
            text: 'As a freelancer, Accounting was always a headache for me. D & Y practice took that burden off my shoulders. Now, I can focus on what I do best!".',
            name: 'Dani K. ',
            role: ' Freelancer',
        },
        {
            id: 3,
            image: 'assets/images/testimonial/03.jpg',
            text: 'The integrity and expertise of David & Yash are unparalleled. They advised us on potential tax risks and helped us mitigate FBT liabilities, ensuring we are always on the right side of the law. It is comforting to know we are in such capable and ethical hands.',
            name: 'Mathew P. ',
            role: 'Manager',
        },
        {
            id: 4,
            image: 'assets/images/testimonial/04.jpg',
            text: ' David & Yash have been our trusted advisors for over 5 years. They have helped us with everything from tax planning to business strategy. Their team is always available to answer our questions and provide us with the best advice',
            name: 'Rosario S.',
            role: ' Director',
        },
        {
            id: 5,
            image: 'assets/images/testimonial/05.jpg',
            text: 'The team at D & Y practice is extremely professional and knowledgeable. They have helped us with everything from tax planning to business strategy. Their team is always available to answer our questions and provide us with the best advice.',
            name: 'Aubee D. ',
            role: '- Ceo',
        },
    
        {
            id: 7,
            image: 'assets/images/testimonial/07.jpg',
            text: 'I thought it was just another tax return appointment, but Yash advised me on the FBT exemption for electric cars. This single tip is projected to save me $30,000 over the next 5 years. I could not be more grateful!',
            name: 'Nicole J. ',
            role: '',
        },
        {
            id: 8,
            image: 'assets/images/testimonial/08.jpg',
            text: 'I have been blown away by the financial strategies offered by D & Y. Their debt recycling plan has been a game-changer for my financial portfolio. On top of that, their advice on maximizing super contributions has set me on a secure path for retirement. Truly exceptional service!',
            name: 'Lena S. ',
            role: 'Dr.',
        },
    ];

    const handleAvatarClick = (index) => {
        setActiveSlide(index);
    };

    const options = {
        items: 1,
        nav: false,
        dots: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 2500,
        autoplayHoverPause: true,
    };

    return (
        <>
            <section class="bg-pos-r" data-bg-img="assets/images/bg/01.webp" style={{ backgroundImage: `url(${require('../../assets/images/bg/01.webp')})` }}>
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-8">
                            <div class="mb-5">
                                <h2><span class="font-w-4 d-block">Hear What Our</span> Clients Have to say?</h2>
                            </div>
                        </div>
                    </div>
                    <div id="testimonial" class="testimonial-carousel carousel slide testimonial z-index-1"
                        data-bs-ride="carousel" data-bs-interval="2500">
                        <div class="row justify-content-center text-center">
                            <div class="col-md-7">
                                <OwlCarousel className="testimonial-carousel" {...options} startPosition={activeSlide}>
                                    {testimonials.map((testimonial, index) => (
                                        <div class="carousel-inner ">
                                            <div key={testimonial.id} className="carousel-item active">
                                                {/* <div key={testimonial.id} className={activeSlide === index ? "carousel-item active" : "carousel-item"}> */}
                                                <div className="card p-2 p-md-5 border-0">
                                                    <div className="mb-3">
                                                        <img alt="Image1"
                                                            src={require(`../../assets/images/testimonial/${testimonial.image.split('/')[3]}`)} className="shadow-primary img-fluid rounded-circle d-inline" style={{ height: '10rem', width: '10rem' }} />
                                                    </div>
                                                    <div className="card-body p-0">
                                                        <p className="lead font-w-5">{testimonial.text}</p>
                                                        <div>
                                                            <h5 className="text-primary d-inline">{testimonial.name}</h5>
                                                            <small className="text-muted fst-italic">{testimonial.role}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>

                        <div class="controls">
                            <ul class="nav justify-content-md-between justify-content-center">
                                {testimonials.map((testimonial, index) => (
                                    <li
                                        key={testimonial.id}
                                        className={activeSlide === index ? "active" : ""}
                                        data-bs-target="#testimonial"
                                        data-bs-slide-to={index}
                                        style={{ cursor: "pointer" }}
                                    >

                                        <img
                                            className="img-fluid rounded-circle shadow-primary"
                                            src={require(`../../assets/images/testimonial/${testimonial.image.split('/')[3]}`)}
                                            alt=""
                                            onClick={() => handleAvatarClick(index)}
                                        />

                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </ >
    );
};
export default TestimonialSection;
