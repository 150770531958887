import React from 'react'
import HerosectionL2 from '../../../Componet/Herosection/HerosectionL2'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import CalculatorButtons from '../../../Componet/Blog/Calculators/CalculatorButtons'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import { Helmet } from 'react-helmet'

function Calculator() {
    return (
        <>
        <Helmet>
            <title>Financial Calculators | Car Loan, Capital Gains, Tax & More | DNY Practice</title>
            <meta name="description" content="Explore our range of financial calculators designed to help you with car loans, capital gains, taxes, and more. Make informed decisions with DNY Practice." />
            <meta name="keywords" content="Calculators, Car Loan, Capital Gains, Tax, Financial Tools, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calculator" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Financial Calculators | Car Loan, Capital Gains, Tax & More" />
            <meta property="og:description" content="Make informed financial decisions with our range of calculators." />
            <meta property="og:url" content="https://dnypractice.com.au/calculator" />
            <meta property="og:type" content="website" />
        </Helmet>


            <HerosectionL2 />
            <div className="page-content">
                <CalculatorButtons />
                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default Calculator
