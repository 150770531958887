import React from 'react';
import './BookkeepingHead.css'; // Make sure to create a corresponding CSS file

const Bookkeepinghead = () => {
  return (
    <div className="container">
      <h1 className="title">Bookkeeping Made Easy</h1>
      <h2 className="subtitle">Relax and Let Us Handle the Numbers</h2>
      <p className="description"> We love Bookkeeping — it's our passion. </p>
      {/*<p className="description">Every business owner knows the importance of meticulous bookkeeping and timely ATO submissions, but that doesn't mean you need to get bogged down by the nitty-gritty.</p> */}
      <p className="description">Your expertise is what drives your business forward. So, why not let us take the reins of your financial tracking? Sit back, focus on your growth, and leave the ledger lines to us
      </p>
          <a href="/calendar" target="_blank" rel="noopener noreferrer">
              <div className="btn btn-primary" style={{ marginBottom: '20px' }}>Book a Free Chat</div>
          </a>
    </div>
  );
}

export default Bookkeepinghead;
