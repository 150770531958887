import React from 'react'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import Mortgage from '../../../Componet/Blog/Calculators/Mortgage'
import { Helmet } from 'react-helmet'

function CapitalGrowth() {
    return (
        <>
        <Helmet>
            <title>Mortage Repayments calculator | DNY Practice</title>
            <meta name="description" content="Mortgage Repayment Calculator" />
            <meta name="keywords" content="Mortgage Repayments Calculator, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calculator/mortgage" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Mortage Repayments calculator" />
            <meta property="og:description" content="Calculate the potential growth of your investments with our tool." />
            <meta property="og:url" content="https://dnypractice.com.au/calculator/mortgage" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionHeader name="Mortgage Repayment Calculator" />
            <div className="page-content">
                <Mortgage />
                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default CapitalGrowth
