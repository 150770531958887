import React from 'react'
import HerosectionL1 from '../../../Componet/Herosection/HerosectionL1'
import FeatureL1 from '../../../Componet/Feature/FeatureL1'
import AboutL1 from '../../../Componet/About//AboutL1'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import TestimonialL1 from '../../../Componet/Testimonial/TestimonialL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import { Helmet } from 'react-helmet';


function Landing1() {
    return (
        <>
            <Helmet>
                <title>Expert Small Business Accounting & Tax Services | Affordable, Honest Advice from Experienced Professionals</title>
                <meta name="description" content="Expert accounting and tax services for individuals and businesses. Maximize your financial potential with our experienced team. Contact us today" />
                <meta name="keywords" content="Accounting, Tax, Small Business, Melbourne" />
                <link rel="canonical" href="https://dnypractice.com.au/" />
                <meta name="language" content="English" />
                <meta name="country" content="Australia" />
                <meta property="og:title" content="Expert Small Business Accounting & Tax Services" />
                <meta property="og:description" content="Affordable, Honest Advice from Experienced Professionals" />
                <meta property="og:url" content="https://dnypractice.com.au/" />
                <meta property="og:type" content="website" />
            </Helmet>

            <HerosectionL1 />
            <div className="page-content">
                <FeatureL1 />
                <AboutL1 />
        
                <TestimonialL1 />
                <BlogL5 />
                <NewsletterL1 />
            </div>
        </>
    )
}

export default Landing1


