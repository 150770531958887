import React, { useState } from 'react'
import { Collapse } from 'reactstrap';
import './AboutFaq.css'

function FAQ() {
    const [openAccordion, setOpenAccordion] = useState(1);

    const toggleAccordion = (accordionIndex) => {
        setOpenAccordion(openAccordion === accordionIndex ? null : accordionIndex);
    };

    const faqs = [
        { question: 'What services do you offer?', answer: 'We offer a range of services including tax preparation, Bookkeeping, Xero Training, Accounting, Business Setups and more..' },
        { question: 'How do I get started with your services?', answer: 'A team member will contact you to understand your business and objectives. They will propose the most suitable way forward and determine if our collaboration is mutually beneficial. The following step involves selecting a package tailored to your business requirements. Keep in mind, all our packages come with a fixed monthly fee, ensuring no unexpected costs.' },
        { question: 'Are you available outside of regular business hours?', answer: 'Yes, we are available outside of regular business hours and offer online meetings on Zoom' },
        { question: 'How do I know which package is right for me?', answer: 'We offer a range of packages to suit your business needs. Our team will help you select the most suitable package for your business.' },
        {question: 'Do you also provide business advice?', answer: 'Our expertise encompasses all areas typically handled by a conventional accountant. Feel free to contact us through email or phone. After assessing your needs, we\'ll present a detailed quote outlining the costs for our advice and the specific services you\'ll receive from the D & Y practice, ensuring clarity and no unexpected surprises'},
        {question: 'Do you offer a free consultation?', answer: 'Yes, we offer a free consultation. We will discuss your business needs and objectives and determine the best way forward.'},
        { 
            question: 'How do i appoint you as a Tax agent for my business?', 
            answer: (
                <span>
                    To appoint us as your Tax Agent, you will need to complete a few steps through the ATO's Online Services for Business. This includes setting up your myGovID, linking it to your ABN, and nominating us as your agent using our registered agent number. After you’ve made the nomination, please inform us, as we won’t receive an automatic notification. The process is straightforward but crucial for the security of your tax information. For a detailed guide on each step, please visit this ATO page: <a href="https://www.ato.gov.au/tax-and-super-professionals/digital-services/in-detail/client-agent-linking-steps" target="_blank" rel="noopener noreferrer">Client Agent Linking</a>.  
                </span>
            )
        },        // Add more FAQs here

    ];

    return (
        <>
            <section>
            
                <div className="container">
                <h2 className="text-center">Frequently Asked Questions</h2> {/* Move this line here and add text-center class */}

                
                    <div className="row align-items-center justify-content-between">
                    
                        <div className="col-12 col-lg-6 mb-8 mb-lg-0">
                            <img src={require("../../assets/images/about/04.png")} alt="Image1" className="img-fluid" />
                        </div>
                        <div className="col-12 col-lg-6 col-xl-5">

                            
                            <div className="accordion py-4" id="accordion">
                                {faqs.map((faq, index) => (
                                    <div className="accordion-item rounded mb-2" key={index}>
                                        <h2 className="accordion-header" id={`heading${index}`}>
                                            <button
                                                className="accordion-button border-0 mb-0 bg-transparent"
                                                color="link"
                                                onClick={() => toggleAccordion(index)}
                                                aria-expanded={openAccordion === index}
                                                aria-controls={`collapse${index}`}
                                            >
                                                {faq.question}
                                            </button>
                                        </h2>
                                        <Collapse isOpen={openAccordion === index} aria-labelledby={`heading${index}`} data-bs-parent="#accordion">
                                            <div className="accordion-body text-muted">
                                                {faq.answer}
                                            </div>
                                        </Collapse>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQ