const TaxCalculator = () => {
  const iframeStyle = {
      display: 'block',
      margin: 'auto',
      maxWidth: '100%',
      border: 'black',
  };

  return (
      <div className="container">
          <div style={iframeStyle}>
              <iframe 
                  width="600" 
                  height="900" 
                  frameborder="0"
                  src="https://onedrive.live.com/embed?resid=5E195D0B1DF2837F%2113694&authkey=%21AEnHOYI_vsXtkdQ&em=2&wdAllowInteractivity=False&AllowTyping=True&ActiveCell='2022-23%20Tax%20Calculator'!A2&Item=calc&wdHideGridlines=True&wdInConfigurator=True&wdInConfigurator=True">
              </iframe>
              <div style={{ maxWidth: '450px', textAlign: 'center', paddingTop: '20px', backgroundColor: '#f8f8f8', border: '1px solid #ddd', margin: 'auto', padding: '15px', fontSize: '16px', color: '#666', lineHeight: '1.5' }}>
              <p><strong>Disclaimer:</strong> The information provided by this calculator is for illustrative purposes only. Be sure to consult a financial professional prior to relying on the results.</p>
            </div>
          </div>
      </div>
  );
};

export default TaxCalculator;
