import React from 'react'
import HerosectionL1 from '../../../Componet/Herosection/HerosectionL1'
import FeatureL1 from '../../../Componet/Feature/FeatureL1'
import AboutL2 from '../../../Componet/About/AboutL1'
import MultisecsL1 from '../../../Componet/Multisec/MultisecsL1'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import TestimonialL1 from '../../../Componet/Testimonial/TestimonialL1'
import BlogL4 from '../../../Componet/Blog/BlogL4'
import HerosectionL5 from '../../../Componet/Herosection/HerosectionL5'
import TeamSection from '../../../Componet/About/TeamSection'


function Doctor() {
    return (
        <>
            <HerosectionL5 />
            <div className="page-content">
                <FeatureL1 />
                <AboutL2 />
                <MultisecsL1 />
                <NewsletterL1 />
                <TestimonialL1 />
                <BlogL4 />
                <TeamSection />

                
            </div>
        </>
    )
}

export default Doctor


