import React from 'react'
import HerosectionL3 from '../../../Componet/Herosection/HerosectionL3'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import Service from '../../../Componet/Service/Service'
import Bookkeeping from '../../../Componet/Service/Bookkeeping'
import Tax from '../../../Componet/Service/Tax'
import BusinessSetups from '../../../Componet/Service/BusinessSetups'
import { Helmet } from 'react-helmet';



function Services() {
    return (
        <>
        <Helmet>
            <title>Comprehensive Accounting & Tax Services | DNY Practice</title>
            <meta name="description" content="Explore our wide range of accounting and tax services designed to meet the unique needs of individuals and businesses. Learn more and get started today." />
            <meta name="keywords" content="Accounting Services, Tax Services, Business Accounting, Individual Tax, Melbourne" />
            <link rel="canonical" href="https://dnypractice.com.au/services" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Comprehensive Accounting & Tax Services" />
            <meta property="og:description" content="Meet the unique needs of individuals and businesses with our wide range of services." />
            <meta property="og:url" content="https://dnypractice.com.au/services" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionL3 />
            <div className="page-content">
                <Service />
                <Bookkeeping />
                <NewsletterL1 />

                <Tax />
                <BusinessSetups />
                <BlogL5 />



                

                
            </div>
        </>
    )
}

export default Services
