import React from 'react'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import FeatureL2 from '../../Componet/Feature/FeatureL2'
import AboutL3 from '../../Componet/About/AboutL3'
import TestimonialL1 from '../../Componet/Testimonial/TestimonialL1'
import BlogL6 from '../../Componet/Blog/BlogL6'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import WhoWeAre from '../../Componet/About/WhoWeAre.js';
import TeamSection from '../../Componet/About/TeamSection' 
import { Helmet } from 'react-helmet'


const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us | Expert Accounting & Tax Professionals | DNY Practice</title>
        <meta name="description" content="Learn more about DNY Practice, your trusted partner for expert accounting and tax services. Our experienced team is committed to your financial success." />
        <meta name="keywords" content="About Us, Accounting, Tax, Team, Melbourne, DNY Practice" />
        <link rel="canonical" href="https://dnypractice.com.au/about-us" />
        <meta name="language" content="English" />
        <meta name="country" content="Australia" />
        <meta property="og:title" content="About Us | Expert Accounting & Tax Professionals" />
        <meta property="og:description" content="Committed to your financial success." />
        <meta property="og:url" content="https://dnypractice.com.au/about-us" />
        <meta property="og:type" content="website" />
      </Helmet>

      <HerosectionHeader name={"About Us"}/>
      <div class="page-content">
        <WhoWeAre />
        <TeamSection />
        <FeatureL2 />
        
        <TestimonialL1 />
        <BlogL6 />
        <NewsletterL1 />
        
        
      </div>
    </>
  )
}

export default AboutUs


  