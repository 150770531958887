import React, { useState } from 'react';
import axios from 'axios'; // Make sure to install axios if you haven't


function Map() {
    const [message, setMessage] = useState(''); // Step 1: Add state


    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const form = e.target;

        const formData = {
            name: form.name?.value || '',
            email: form.email?.value || '',
            phone: form.phone?.value || '',
            subject: form.subject?.value || '',
            message: form.message?.value || '',
            service: form.service?.value || '' // Make sure to add a 'name' attribute to the select field
          };

        try {
            const response = await axios.post('https://yizbqpmked.execute-api.ap-southeast-2.amazonaws.com/prod/send-email', formData);
            if (response.status === 200) {
                setMessage('Email sent successfully!'); // Step 2: Update state
                // You can add more logic here, like resetting the form or displaying a success message
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setMessage('Error sending email. Please try again.'); // Update state with error message

            // Handle the error appropriately
        }
    };

    return (
        <>
            <section >
                <div className='container'>
                    <div className='row'>
                        <div className="col-12 col-lg-7">
                            <div>
                                <div>
                                    <h4><span class="font-w-4 d-block style={{ paddingBottom: '100px' }}">Send Message</span> </h4>
                                </div>
                                <form id="contact-form" className="row" method="post" action="php/contact.php" onSubmit={handleSubmit}>
                                    <div className="messages" />
                                    <div className="form-group col-md-6">
                                        <input id="form_name" type="text" name="name" className="form-control" placeholder="First Name" required="required" data-error="Name is required." />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input id="form_name1" type="text" name="name" className="form-control" placeholder="Last Name" required="required" data-error="Name is required." />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input id="form_email" type="email" name="email" className="form-control" placeholder="Email" required="required" data-error="Valid email is required." />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input id="form_phone" type="tel" name="phone" className="form-control" placeholder="Phone" required="required" data-error="Phone is required" />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <select className="form-control">
                                            <option>- Select Service</option>
                                            <option>Consulting</option>
                                            <option>Accouting & Tax</option>
                                            <option>Bookkeeping</option>
                                            <option>BusinessSetups</option>
                                            <option>Tax Returns</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input id="form_subject" type="tel" name="subject" className="form-control" placeholder="Subject" required="required" data-error="Subject is required" />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <textarea id="form_message" name="message" className="form-control h-auto" placeholder="Message" rows={4} required="required" data-error="Please,leave us a message." defaultValue={""} />
                                        <div className="help-block with-errors" />
                                    </div>
                                    <div className="col mt-4" style={{ paddingBottom: '60px' }}>
                                        <button className="btn btn-primary" type="submit">Send</button>
                                    </div>
                                    {message && <div>{message}</div>} {/* Step 3: Conditionally render message */}

                                    
                                </form>
                            </div>
                        </div>

                        
                        <div className="col-12 col-lg-4 ms-auto mt-5 mt-lg-0">
                            <div class="d-flex align-items-center bg-white p-3 shadow-sm rounded mb-3">
                                <div class="me-3">
                                    <div class="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}> <i class="flaticon-location"></i>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="mb-1"></h5>
                                    <span class="text-black">We're a 100% virtual firm, serving clients all across Australia.</span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center bg-white p-3 shadow-sm rounded  mb-3">
                                <div class="me-3">
                                    <div class="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}> <i class="flaticon-mail"></i>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="mb-1"></h5>
                                    <a class="btn-link" href="mailto:themeht23@gmail.com"> mailto:info@dnypractice.com.au</a>
                                </div>
                            </div>
                            <div class="d-flex align-items-center bg-white p-3 shadow-sm rounded">
                                <div class="me-3">
                                    <div class="f-icon-s p-3 rounded" style={{ backgroundColor: "#d0faec" }}> <i class="flaticon-telephone"></i>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="mb-1"></h5>
                                    <a class="btn-link" href="tel:+912345678900">1300 948 816</a>
                                </div>
                            </div>
                            <div className='map h-50 mt-5'>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Map
