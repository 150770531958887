import React from 'react';
import './PricingTable.css'; // Make sure to create a corresponding CSS file

const data = [
  {
    "plan": "Small Business Plan",
    "for": "Established small businesses with less than $500k in revenue",
    "price": "$500/month",
    "features": [
      "Comprehensive bookkeeping services",
      "Bills and expenses management with Hubdoc",
      "Quarterly BAS lodgement",
      "ASIC compliance",
      "Year-end tax support",
      "Annual financial statements",
      "Annual tax return",
      "Monthly Payroll services for up to 5 employees",
      "Year-end payroll reconciliation",
    ]
  },
  {
    "plan": "Premium Small Business Plan",
    "for": "Small to medium businesses with upto $1M in revenue",
    "price": "$650/month",
    "features": [
      "Comprehensive bookkeeping services",
      "Bills and expenses management with Hubdoc",
      "Quarterly BAS lodgement",
      "ASIC compliance",
      "Year-end tax support",
      "Annual financial statements",
      "Annual tax return",
      "Monthly Payroll services for up to 10 employees",
      "Year-end payroll reconciliation",
    ]
  },
  {
    "plan": "Enterprise Plan",
    "for": "Large businesses with custom needs",
    "price": "Custom pricing",
    "features": [
      "Tailored comprehensive accounting services",
      "Custom BAS lodgement schedule",
      "ASIC annual compliance",
      "Custom tax support and planning",
      "Bespoke financial statements and reporting",
      "Custom payroll solutions",
      "Dedicated senior accountant or partner support"
    ]
  }
];


const PricingTable = () => {
  return (
    <div className="pricing-table">
      {data.map((item, index) => (
        <div key={index} className="pricing-option">
          <h2>{item.plan}</h2>
          <p className="price">{item.price}</p>
          <p className="for">{item.for}</p>
          <a href="/calendar" className="btn btn-primary" target="_blank" rel="noopener noreferrer">
            Get started now
          </a>         
          <ul>
            {item.features.map((feature, featureIndex) => (
              <li key={featureIndex}>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default PricingTable;
