import React, { useState, useEffect } from 'react';

function WhoWeAre() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div 
      className="who-we-are-section" 
      style={{ 
        width: windowWidth > 768 ? '67%' : '100%', 
        margin: '0 auto' 
      }}
    >
      <hr /> 
      <p style={{ padding: windowWidth <= 768 ? '20px' : '0' }}>The D & Y Practice is a forward-thinking accounting firm nestled in the heart of Melbourne, Australia, extending a welcoming hand to individuals, startups, eCommerce enterprises, and small-to-medium-sized tech-enabled businesses across the vast expanses of Australia. Our status as registered tax agents and seasoned business advisors empowers us with the acumen to deliver precise, timely financial insights, a beacon of certainty in the ever-evolving tax landscape of Australia.</p>
      <br />
      <p style={{ padding: windowWidth <= 768 ? '20px' : '0' }}>
      
      What sets The D & Y Practice apart is our profound embrace of technology-driven solutions. We meld modern accounting software and tools with our rich expertise, orchestrating a symphony of streamlined processes that accentuate the efficiency and precision of our services. This tech-savvy approach not only augments the accuracy and reliability of our financial stewardship but significantly trims down the time and resources traditionally required in accounting realms.      </p>
      <br />
      <p style={{ padding: windowWidth <= 768 ? '20px' : '0' }}>
      Our ethos is steeped in flexibility and a personalized touch, acknowledging the rich tapestry of needs and aspirations each business brings to the table. With a culture that cherishes after-hours consultations and virtual engagements, we transcend the conventional boundaries of time and space to meet you at the crossroads of your financial quests. Our journey with each client begins with an earnest endeavor to fathom their unique narrative, their ambitions, and their challenges, paving the way for tailored advice and a companionship aimed at navigating the tumultuous seas towards financial prosperity.</p>
      <br />
      <p style={{ padding: windowWidth <= 768 ? '20px' : '0' }}>
      From setting up robust accounting systems, managing the lifelines of cash flow, preparing diligent tax returns, to crafting insightful financial reports, our services are a tapestry of support aimed at nurturing the seeds of your financial goals into towering trees of success.</p>
      <br />
      <p style={{ padding: windowWidth <= 768 ? '20px' : '0' }}>
      At The D & Y Practice, our commitment transcends beyond mere services. We embark together with individuals and SMBs on a voyage towards financial clarity and success, offering the compass of our expertise and the sails of our support in the tempest of financial challenges. Our endeavor is not just to be your accounting firm, but a partner in your narrative of success, a testament to our core values of honesty, integrity, and unwavering support.      </p>

    </div>
  );
}

export default WhoWeAre;












{/*


import React from 'react';
import { Container } from 'reactstrap';  // Importing Container from reactstrap


const WhoWeAre = () => {
  return (
    <Container>
        <div 
          className="who-we-are-section" 
          style={{ 
            width: window.innerWidth > 768 ? '70%' : '100%', 
            margin: '0 auto' 
          }}
        >      
        <h2 style={{ textAlign: 'center' }}>Who We Are</h2>
      <hr /> 
      <p>The D & Y Practice is a forward-thinking accounting firm nestled in the heart of Melbourne, Australia, extending a welcoming hand to individuals, startups, eCommerce enterprises, and small-to-medium-sized tech-enabled businesses across the vast expanses of Australia. Our status as registered tax agents and seasoned business advisors empowers us with the acumen to deliver precise, timely financial insights, a beacon of certainty in the ever-evolving tax landscape of Australia.</p>
      <br />
      <p>
      
      What sets The D & Y Practice apart is our profound embrace of technology-driven solutions. We meld modern accounting software and tools with our rich expertise, orchestrating a symphony of streamlined processes that accentuate the efficiency and precision of our services. This tech-savvy approach not only augments the accuracy and reliability of our financial stewardship but significantly trims down the time and resources traditionally required in accounting realms.      </p>
      <br />
      <p>
      Our ethos is steeped in flexibility and a personalized touch, acknowledging the rich tapestry of needs and aspirations each business brings to the table. With a culture that cherishes after-hours consultations and virtual engagements, we transcend the conventional boundaries of time and space to meet you at the crossroads of your financial quests. Our journey with each client begins with an earnest endeavor to fathom their unique narrative, their ambitions, and their challenges, paving the way for tailored advice and a companionship aimed at navigating the tumultuous seas towards financial prosperity.</p>
      <br />
      <p>
      From setting up robust accounting systems, managing the lifelines of cash flow, preparing diligent tax returns, to crafting insightful financial reports, our services are a tapestry of support aimed at nurturing the seeds of your financial goals into towering trees of success.</p>
      <br />
      <p>
      At The D & Y Practice, our commitment transcends beyond mere services. We embark together with individuals and SMBs on a voyage towards financial clarity and success, offering the compass of our expertise and the sails of our support in the tempest of financial challenges. Our endeavor is not just to be your accounting firm, but a partner in your narrative of success, a testament to our core values of honesty, integrity, and unwavering support.      </p>

    </div>
    </Container>

  );
};

export default WhoWeAre;*/}




