import React from 'react'
import HerosectionL6 from '../../../Componet/Herosection/HerosectionL6'
import FeatureL1 from '../../../Componet/Feature/FeatureL1'
import AboutL2 from '../../../Componet/About/AboutL1'
import MultisecsL1 from '../../../Componet/Multisec/MultisecsL1'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import TestimonialL1 from '../../../Componet/Testimonial/TestimonialL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import HerosectionL5 from '../../../Componet/Herosection/HerosectionL5'
import TeamSection from '../../../Componet/About/TeamSection'
import AllInclusivePackage from '../../../Componet/AllinclusivePackage/Package'


function Freelancer() {
    return (
        <>
            <HerosectionL6 />
            <div className="page-content">
                <FeatureL1 />
                <AboutL2 />
                <MultisecsL1 />
                <NewsletterL1 />
                <TestimonialL1 />
                <BlogL5 />
                <TeamSection />
                

                
            </div>
        </>
    )
}

export default Freelancer


