import React, { useState } from 'react'
import Modal from './../Modal.js'; // Adjust the import path as needed
import AllInclusivePackage from './../AllinclusivePackage/Package.js';

function AboutL1() {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };


    const gototop=()=>{
      window.scrollTo({top:0,behavior:"smooth"})
  }
  return (
    <>
         <section className="py-5">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-lg-7 mb-5 mb-lg-0">
                <img
                  src={require("../../assets/images/about/01.webp")}
                  alt="Image1"
                  className="img-fluid rounded"
                />
              </div>
              <div className="col-12 col-lg-5">
                <div className="mb-5">
                  <h2>
                    <span className="font-w-4 d-block">Tailored Solutions</span> For
                    your Business
                  </h2>
                  <p className="lead mb-0">
                  Utilizing the latest technologies to ensure compliance and maximize your profits.
                  </p>
                </div>
                <div className="d-flex align-items-start mb-4">
                  <div className="me-3">
                    <span className="list-dot" style={{backgroundColor:"#01a479"}}></span>
                  </div>
                  <div>
                    <h6 className="mb-2">Comprehensive Tax Solutions</h6>
                    <p className="mb-0">
                    From tax planning to filing, we offer comprehensive solutions to minimize your tax liability.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="me-3">
                    <span className="list-dot" style={{backgroundColor:"#ffbe30"}} ></span>
                  </div>
                  <div>
                    <h6 className="mb-2">Fixed-Price Packages</h6>
                    <p className="mb-0">
                    Our fixed-price accounting packages offer transparent pricing and no hidden fees, giving you peace of mind
                    </p>
                  </div>
                  </div>
                  
                
                
                <div className="btn btn-outline-primary mt-5" onClick={toggleModal}>
                  Explore More
                </div>
                <Modal show={showModal} onClose={toggleModal}>
                <h2 style={{ fontSize: '1.2em' }}>Fixed Price Accounting</h2>
   

      
   <strong style={{textAlign: 'center'}}>Compliance</strong>
   <ul>
        <li>Reviewing your Xero File</li>
        <li>Year-end adjustments</li>
        <li>Completing annual Financial Statements</li>
        <li>Completing and filing your entity tax returns plus returns for two shareholders</li>
        <li>Completing and filing your ASIC annual return</li>
        <li>Preparing your minutes and resolutions</li>
    </ul>
    <strong>Tax Planning</strong>
    <ul>
      <li>Strategic tax planning to minimize your liability.</li>

   </ul>
    <strong>Budgeting & Goal Setting</strong>
    <ul>
      <li>Budgeting</li>
      <li>Quarterly business performance review</li>
    </ul>      
    
    <a href="https://koalendar.com/e/meet-with-dny" target="_blank" rel="noopener noreferrer">
          <div className="btn btn-primary">Book a Consultation</div>
    </a>
                </Modal>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default AboutL1
