import React from 'react'
import HerosectionL3 from '../../../Componet/Herosection/HerosectionL3'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import NegativeGearing from '../../../Componet/Blog/Calculators/NegativeGearing'
import { Helmet } from 'react-helmet'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'

function Car() {
    return (
        <>
        <Helmet>
                <title>Negative Gearing Calculator | Optimize Your Investments | DNY Practice</title>
                <meta name="description" content="Optimize your property investments with our Negative Gearing Calculator. Ideal for investors looking to understand the financial implications of negative gearing. Try it now at DNY Practice." />
                <meta name="keywords" content="Negative Gearing Calculator, Property Investment, Financial Planning, Melbourne, DNY Practice" />
                <link rel="canonical" href="https://dnypractice.com.au/calculator/negativegearing" />
                <meta name="language" content="English" />
                <meta name="country" content="Australia" />
                <meta property="og:title" content="Negative Gearing Calculator | Optimize Your Investments" />
                <meta property="og:description" content="Understand the financial implications of negative gearing with our calculator." />
                <meta property="og:url" content="https://dnypractice.com.au/calculator/negativegearing" />
                <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionHeader name="Negative Gearing Calculator" />
            <div className="page-content">
                <NegativeGearing />

                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default Car
