import React from 'react'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import FrankingCredit from '../../../Componet/Blog/Calculators/FrankingCredit'
import { Helmet } from 'react-helmet'

function Car() {
    return (
        <>
        <Helmet>
            <title>Franking Credit Calculator | Accurate & Easy-to-Use | DNY Practice</title>
            <meta name="description" content="A franking credit calculator can help estimate the value of franking credits from dividends. Try it now at DNY Practice." />
            <meta name="keywords" content="Franking Credit Calculator, what are Franking credits, Frankiing credits, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calculator/car" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Franking Credit Calculator | Accurate & Easy-to-Use" />
            <meta property="og:description" content="Plan your finances better with our Franking Credit Calculator" />
            <meta property="og:url" content="https://dnypractice.com.au/calculator/frankingcredit" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionHeader name="Franking Credit Calculator" />
            <div className="page-content">
                <FrankingCredit />
                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default Car
