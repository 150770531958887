import React, { useState, useEffect } from 'react';
import './WordSearch.css';

const words = ['ACCOUNTING', 'TAX', 'CREDIT', 'WHOOPS', 'BAMBOOZLE', 'VANISH', 'NADA', 'FRANKING'];

const WordSearch = () => {
  const [grid, setGrid] = useState([]);
  const [selectedCells, setSelectedCells] = useState([]);
  const [foundWords, setFoundWords] = useState([]); // New state to keep track of found words


  useEffect(() => {
    let newGrid = [];

    // Initialize grid with random letters
    for (let i = 0; i < 12; i++) {
      newGrid[i] = [];
      for (let j = 0; j < 12; j++) {
        newGrid[i][j] = String.fromCharCode(Math.floor(Math.random() * 26) + 65);
      }
    }

    // Insert words into grid (simplified, horizontal only)
    words.forEach(word => {
      const row = Math.floor(Math.random() * 10);
      const col = Math.floor(Math.random() * (11 - word.length));
      for (let i = 0; i < word.length; i++) {
        newGrid[row][col + i] = word[i];
      }
    });

    setGrid(newGrid);
  }, []);

  const toggleCell = (rowIndex, cellIndex) => {
    const key = `${rowIndex}-${cellIndex}`;
    setSelectedCells(prev => {
      if (prev.includes(key)) {
        return prev.filter(k => k !== key);
      } else {
        return [...prev, key];
      }
    });
  };

  const checkWord = () => {
    const selectedWord = selectedCells.map(key => {
      const [row, col] = key.split('-').map(Number);
      return grid[row][col];
    }).join('');

    if (words.includes(selectedWord)) {
      alert(`You found ${selectedWord}!`);
    }
  };

  const markWordAsFound = (word) => {

    setFoundWords([...foundWords, word]);
  };


  return (
    <div className="game-container">
      <div className="words-to-find">
        {words.map((word) => (
          <span 
            key={word} 
            className={foundWords.includes(word) ? 'found' : ''}
            onClick={() => markWordAsFound(word)}
          >
            {word}
          </span>
        ))}
      </div>
      <div className="grid-container">
        {grid.map((row, rowIndex) => (
          <div className="grid-row" key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <div
                className={`grid-cell ${selectedCells.includes(`${rowIndex}-${cellIndex}`) ? 'selected' : ''}`}
                key={cellIndex}
                onMouseDown={() => toggleCell(rowIndex, cellIndex)}
                onMouseUp={checkWord}
              >
                {cell}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WordSearch;


  












