import React from 'react';

const TeamMember = ({ name, role, image, social, blurb, meetinglink }) => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-12">
      <div className="team-member">
        <div style={{ textAlign: 'center' }}>
          <img src={image} alt={name} style={{ marginBottom: '20px' }} />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <h3>{name}</h3>
          <div className="social-icons ml-auto">
            {social.map((s, index) => (
              <a key={index} href={s.link}>
                <i className={s.icon}></i>
              </a>
            ))}
          </div>
        </div>
        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{role}</p>
        <p className="blurb" style={{ textAlign: 'center' }}>{blurb}</p>
        <div style={{ textAlign: 'center' }}>
          <a href={meetinglink} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Schedule Meeting</a>
        </div>
        
      </div>
    </div>
  );
};


const TeamSection = () => {
  
    const TeamMembersData = [
        {
            id: 1,
            name: 'David Seth',
            role: 'Director, CTA, IPA',
            blurb: 'David specializes in Australian tax and has over 30 years of experience. He is a Chartered tax Advisor and a member of IPA and a Tax agent.',

            image: require('../../assets/images/team/010.png'),
            social: [
               
                { id: 4, icon: 'la la-linkedin', link: 'https://www.linkedin.com/in/david-seth-70520896/' },
            ],
            meetingLink: 'https://koalendar.com/e/meet-with-david-seth',

        },
        
        {
            id: 2,
            name: 'Yash Arora',
            role: 'Director, CA',
            blurb: 'Yash is an expert in cloud computing and has a passion for technology. He is a Chartered Accountant and AWS cloud Practitioner.',

            image: require('../../assets/images/team/020.png'),
            social: [
               
                { id: 4, icon: 'la la-linkedin', link: 'https://www.linkedin.com/in/yash-arora-b88b2210/' },
            ],
            meetingLink: 'https://koalendar.com/e/meet-with-dny',

        }
  ];

  return (
    <section>
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-12 col-lg-8">
            <div className="mb-5">
              <h2>
                <span className="font-w-4">Meet Our</span> Experts
              </h2>
              
            </div>
          </div>
        </div>
        <div className="row mb-5 justify-content-center align-items-center">
          {TeamMembersData.map((member) => (
            <TeamMember key={member.id} name={member.name} role={member.role} image={member.image} social={member.social} blurb={member.blurb} meetinglink={member.meetingLink} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
