import React from 'react'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'

import BlogL5 from '../../../Componet/Blog/BlogL5'
import Equity from '../../../Componet/Blog/Calculators/Equity'
import { Helmet } from 'react-helmet'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'

function Equity1() {
    return (
        <>
        <Helmet>
                <title>Return on Equity Calculator | Optimize Your Investments | DNY Practice</title>
                <meta name="description" content="Optimize your property investments with our Negative Gearing Calculator. Ideal for investors looking to understand the financial implications of negative gearing. Try it now at DNY Practice." />
                <meta name="keywords" content="Return on Equity Calculator, Property Investment, Financial Planning, Melbourne, DNY Practice" />
                <link rel="canonical" href="https://dnypractice.com.au/calculator/negativegearing" />
                <meta name="language" content="English" />
                <meta name="country" content="Australia" />
                <meta property="og:title" content="Return on Calculator | Optimize Your Investments" />
                <meta property="og:description" content="Understand the financial implications of property investment" />
                <meta property="og:url" content="https://dnypractice.com.au/calculator/negativegearing" />
                <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionHeader name="Return on Equity Calculator" />
                
                <Equity />

                <NewsletterL1 />
                <BlogL5 />
                

                
            </>
    )
}

export default Equity1
