import React from 'react';
import './CaseStudy.css'; // Make sure you also rename the CSS file to match this import
// Import images
import freelancerImage from '../../../src/assets/images/about/freelancer.png';
import problemImage from '../../../src/assets/images/about/Freelancer2.png';
import happyImage from '../../../src/assets/images/about/freelancer3.png';


import xeroImage from '../../../src/assets/images/about/02.jpg';

const CaseStudy1 = () => {
  return (
    <div className="caseStudy-container">
      <h1 className="caseStudy-title">Freelancer Case Study</h1>

      {/* Introduction */}
      <h2 className="caseStudy-subtitle">Introduction</h2>
      <div className="caseStudy-section caseStudy-intro">
        <img src={freelancerImage} alt="Salon Interior" className="caseStudy-image" />
        <p className="caseStudy-text">Jane is a Melbourne-based freelance graphic designer with a passion for bringing ideas to life through creative design. With a growing clientele and numerous projects, Jane’s business was thriving, but her accounting and bookkeeping workflow were becoming increasingly time-consuming and complex.</p>
      </div>
      <hr /> {/* Separator */}

      {/* Problem Statement */}
      <h2 className="caseStudy-subtitle">Problem Statement</h2>
      <div className="caseStudy-section caseStudy-problem">
        <img src={problemImage} alt="Problem Scenario" className="caseStudy-image" />
        <p className="caseStudy-text">Jane’s accounting and bookkeeping were managed manually, using spreadsheets and physical invoices. This system was not only inefficient but also prone to errors. With tax deadlines looming and her business expanding, Jane needed a solution that could simplify her financial management without detracting from her creative work.She also missed out on superannaution contributions for the past few years</p>
      </div>
      <hr /> {/* Separator */}

      {/* Solution */}
      <h2 className="caseStudy-subtitle">Solution</h2>
      <div className="caseStudy-section caseStudy-intro">
        <img src={xeroImage} alt="Salon Interior" className="caseStudy-image" />
        <p className="caseStudy-text">DNY Practice revolutionized Jane's financial workflow by introducing a comprehensive, cloud-based accounting system specifically designed for the unique needs of freelancers. We seamlessly transitioned her from a cumbersome manual process to a streamlined digital platform, ensuring her financial activities were automated and easily manageable. This transition included personalized setup of the software, integration of her business activities, and one-on-one training sessions to ensure Jane could navigate the new system with confidence. Our solution aimed not just to simplify the bookkeeping process but to empower Jane to take charge of her financial health with intuitive tools at her fingertips.</p>
      </div>
      <hr /> {/* Separator */}

      {/* Conclusion */}
      <h2 className="caseStudy-subtitle">Conclusion</h2>
      <div className="caseStudy-section caseStudy-problem">
        <img src={happyImage} alt="Problem Scenario" className="caseStudy-image" />
        <p className="caseStudy-text">The transformation was nothing short of remarkable. Jane's accounting workflow became a well-oiled machine, delivering efficiency and clarity. She slashed her administrative time by half, freeing up precious hours to channel into her creative work. The accuracy of her financial reporting soared, eliminating the stress of potential errors and providing her with a clear picture of her financial status. Empowered by this new system, Jane was able to make strategic decisions quickly and with confidence. As tax season approached, what was once a source of anxiety became a smooth and stress-free process, with all her financials organized and readily accessible. The tangible benefits of our solution were clear: time saved, confidence gained, and a greater focus on growing her business.</p>
      </div>
    </div>
  );
};

export default CaseStudy1;
