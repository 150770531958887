import React from 'react';
import { Link } from 'react-router-dom';
import './SuccessPage.css'; // Import your CSS file

const SuccessPage = () => {
  return (
    <div className="success-container">
      <div className="success-content">
        <h2>Thanks for making a Booking with us!</h2>
        <p>We are excited to serve you at the scheduled online meeting time.</p>
        <p> In the meantime, feel free to explore our </p><Link to="https://blog.dnypractice.com.au/"> Informative blogs</Link> <p>or play around with some Tax</p><Link to="https://dnypractice.com.au/calculator">Calculators</Link>
      </div>
    </div>
  );
};

export default SuccessPage;
