import React from 'react'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import { Helmet } from 'react-helmet'
import Stage3TaxCalculator from '../../../Componet/Blog/Calculators/Stage3TaxCalculator'

function Stage3Tax() {
    return (
        <>
        <Helmet>
            <title>Stage 3 tax cuts calculator | DNY Practice</title>
            <meta name="description" content="Mortgage Repayment Calculator" />
            <meta name="keywords" content="Mortgage Repayments Calculator, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calculator/stage3tax" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Mortage Repayments calculator" />
            <meta property="og:description" content="Calculate the potential growth of your investments with our tool." />
            <meta property="og:url" content="https://dnypractice.com.au/calculator/stage3tax" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionHeader name="Stage 3 Tax cut Comparison" />
            <div className="page-content">
                <Stage3TaxCalculator />
                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default Stage3Tax
