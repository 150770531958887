import React, { useState } from 'react';
import './CarLoanCalculator.css'; 

const FrankingCreditCalculator = () => {
  const [rate, setRate] = useState('');
  const [amount, setAmount] = useState('');
  const [percentage, setPercentage] = useState('');
  const [finalAmount, setFinalAmount] = useState(null);

  const frankingCredit = () => {
    const taxRate = parseFloat(rate) / 100;
    const localAmount = parseFloat(amount);  // Renamed to localAmount
    const localPercentage = parseFloat(percentage);  // Renamed to localPercentage

    const frankingCreditValue = ((localAmount / (1 - taxRate))-localAmount) * (localPercentage / 100);  // Updated to use localAmount and localPercentage

    setFinalAmount(frankingCreditValue.toFixed(2));
  };

  return (
    <div className="calculator-and-blog-container">

    <div className="calculator-container text-centre">
      <h3>Franking Credit Calculator</h3>
      <form className="calculator-form">
        <label>
          Company Tax Rate (%):
          <input type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
        </label>
        <label>
          Franked Amount:
          <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
        </label>
        <label>
          Franking percentage %:
          <input type="number" value={percentage} onChange={(e) => setPercentage(e.target.value)} />
        </label>
        
        <button type="button" className="btn btn-primary" onClick={frankingCredit}>
          Calculate
        </button>
      </form>
      {finalAmount && <p>Franking Credit: ${finalAmount}</p>}
      <div style={{ maxWidth: '450px', textAlign: 'center', paddingTop: '20px', backgroundColor: '#f8f8f8', border: '1px solid #ddd', margin: 'auto', padding: '15px', fontSize: '16px', color: '#666', lineHeight: '1.5' }}>
             <p><strong>Disclaimer:</strong> The information provided by this calculator is for illustrative purposes only. Be sure to consult a financial professional prior to relying on the results.</p>
            </div>
    </div>
    
    <div className="blog-container">
        <h3>Understanding Franking Credits: A Quick Guide for Australians</h3>
        {/* Embedding the blog content here */}
        <p>Franking Credits, also known as imputation credits, are a unique aspect of the Australian taxation system. But what are they, and why should you care?</p>
        <h4> What Are Franking Credits?</h4>
        <p>In simple terms, Franking Credits represent the tax that a company has already paid on its profits. When these profits are passed on to shareholders in the form of dividends, the Franking Credits are attached to those dividends.</p>
        <h4> Why Do They Exist?</h4>
        <p> The main idea behind Franking Credits is to prevent double taxation. Without them, the same income could be taxed twice: once at the company level and again at the individual shareholder level. By using Franking Credits, shareholders get a credit for the tax that the company has already paid.</p>
        <h4> How Does This Benefit You?</h4>
        <p>For shareholders, Franking Credits can be a boon. When you receive a dividend with a Franking Credit, you can use that credit to reduce the tax you owe on your dividend income. Essentially, it means you might end up paying less tax or even receive a tax refund.</p>
        <h4> Making the Most of Franking Credits</h4>
        <p>To help you determine how Franking Credits affect your tax situation, using a Franking Credit calculator can be invaluable. This tool will help you understand the value of your Franking Credits and how they impact your overall tax obligations.</p>



      </div>
    </div>
  );
};

export default FrankingCreditCalculator;
