/**
 *  Main Menu Json
 */
export default  [
   
   
   {
      "menu_title": "About Us",
      "type": "subMenu",
      "path": "/",
      "icon": "blog",
      "child_routes": [
         {
            "path": "/about-us",
            "menu_title": "Who We Are",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/services",
            "menu_title": "Services",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/pricing",
            "menu_title": "Pricing",
            "icon": "arrow_right_alt",
            "child_routes": null
         },

      ]
   },
   {
      "menu_title": "Resources",
      "type": "subMenu",
      "path": "/",
      "icon": "blog",
      "child_routes": [
         {
            "path": "https://blog.dnypractice.com.au/",
            "menu_title": "Blogs",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/calculator",
            "menu_title": "Calculators",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         
         
         {
            "path": "https://www.ato.gov.au/Tax-professionals/Prepare-and-lodge/Due-dates/",
            "menu_title": "Key Dates",
            "icon": "arrow_right_alt",
            "child_routes": null,
            "target": "_blank"
         },
         

      ]
   },

  
   {
      "menu_title": "Contact Us",
      "path": "/contact",
      "mega": true,
      "icon": "party_mode",
      
      
   },
   

   {
      "menu_title": "Book a Free Consultation",
      "path": "/calendar",
      "mega": true,
      "icon": "party_mode",
      "highlighted": true
      
      
   }

]


{/*
   {
      "menu_title": "Services",
      "type": "subMenu",
      "path": "/",
      "icon": "shop",
      "child_routes": [
         {
            "path": "/bookkeeping",
            "menu_title": "Bookeeping",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/taxation",
            "menu_title": "Taxation",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/small-business-services",
            "menu_title": "Small Business Services",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/individual-tax",
            "menu_title": "Individual Tax",
            "icon": "arrow_right_alt",
            "child_routes": null
         },

         
      ]
   }, */}