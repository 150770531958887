import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Row, Col } from 'reactstrap';

function Tax() {
  
  const featuresData = [
    {
      icon: 'flaticon-dashboard',
      bgColor: '#d0faec',
      title: 'Tax Compliance',
      description: 'Ensuring your financial affairs are in line with Australian tax regulations.',
    },
    {
      icon: 'flaticon-relationship',
      bgColor: '#ffeff8',
      title: 'Tax Planning',
      description: 'Strategically minimize tax liabilities with forward-thinking advice and planning.',
    },
    {
      icon: 'flaticon-solution',
      bgColor: '#d3f6fe',
      title: 'GST Assistance',
      description: 'Navigate Goods and Services Tax with clarity, ensuring accurate submissions and claims.',
    },
    {
      icon: 'flaticon-system',
      bgColor: '#fff5d9',
      title: 'Audit Support',
      description: 'Guidance and support during tax audits, ensuring smooth interactions with tax authorities..',
    },
  ];

  return (
    <div>
      <section>
        <div class="container" style={{ width: '70%', margin: '0 auto' }} >
          <div class="row align-items-center">
            <div class="col-lg-12 col-12">
              <div class="mb-5">
                <h1 class="mb-0"><span class="font-w-4 d-block text-center  ">Tax Services</span> </h1>
              </div>
              <Row>
                {featuresData.map((feature, index) => (
                  <Col key={index} md="6" className={`mt-5`}>
                    <div className="d-flex justify-content-between">
                      <div className="me-3">
                        <div className={`f-icon-s p-3 rounded`} style={{ backgroundColor: feature.bgColor }}>
                          <i className={feature.icon}></i>
                        </div>
                      </div>
                      <div>
                        <h5 className="mb-2">{feature.title}</h5>
                        <p className="mb-0">{feature.description}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Tax