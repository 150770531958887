import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import PracticeLogo from '../../assets/images/about/dny.png';


function Footer1() {
    const [Visible, setVisible] = useState(false)
    const [loader, setLoader] = useState(true);
    const handleScroll = () => {
        var scrollTop =
            (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        if (scrollTop > 100) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (loader === true) {
            setTimeout(() => setLoader(false), 2000);
        }
    }, [loader]);
    const gototop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5 col-xl-4 me-auto mb-5 mb-lg-0">
                        {/*<Link to="/">
                                <img src={PracticeLogo} alt="Practice Logo" style={{ height: '50px', width: 'auto' }}/> </Link>
                           <Link
                                className="footer-logo h2 text-primary mb-0 font-w-7"
                                to="/"
                            >
                                the <span className="text-dark font-w-4">D & Y practice</span>
    </Link> */}
                            <p className="my-3">
                            We take the hassle out of accounting and tax so you can focus on conquering your world, whatever that may be. As a fully virtual firm, we're not just in your corner; we're in your pocket, making financial management seamless no matter where you are.
                            </p>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <Link className="border rounded px-2 py-1 text-dark" to="https://www.facebook.com/SethArorapty/"
                                    ><i className="la la-facebook"></i></Link>
                                </li>
                                { /* <li className="list-inline-item">
                                    <Link className="border rounded px-2 py-1 text-dark" to="/"
                                    ><i className="la la-dribbble"></i></Link>
                                </li> 
                                <li className="list-inline-item">
                                    <Link className="border rounded px-2 py-1 text-dark" to="/"
                                    ><i className="la la-instagram"></i></Link>
                                </li> */}
                                <li className="list-inline-item">
                                    <Link className="border rounded px-2 py-1 text-dark" to="/"
                                    ><i className="la la-twitter"></i></Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link className="border rounded px-2 py-1 text-dark" to="/"
                                    ><i className="la la-linkedin"></i></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-7">
                            <div className="row">
                                <div className="col-12 col-sm-4 navbar-light">
                                    <h5 className="mb-4">Pages</h5>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-3">
                                            <Link className="list-group-item-action" to="/about-us/"
                                            >About</Link>
                                        </li>
                                        
                                        <li className="mb-3">
                                            <Link className="list-group-item-action" to="https://blog.dnypractice.com.au/"
                                            >Blog</Link>
                                        </li>
                                        <li className="mb-3">
                                            <Link className="list-group-item-action" to="/faq">Faq</Link>
                                        </li>
                                        <li>
                                            <Link className="list-group-item-action" to="/contact/"
                                            >Contact Us</Link>
                                        </li>
                                        

                                    </ul> 
                                </div>
                                <div className="col-12 col-sm-4 mt-6 mt-sm-0 navbar-light">
                                     <h5 className="mb-4">Services</h5>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-3">
                                            <Link className="list-group-item-action" to="/services"
                                            >Business Setups</Link>
                                        </li>
                                        <li className="mb-3">
                                            <Link className="list-group-item-action" to="/services"
                                            >Accounting</Link>
                                        </li>
                                        <li className="mb-3">
                                            <Link className="list-group-item-action" to="/services"
                                            >Tax Compliance</Link>
                                        </li>
                                        <li className="mb-3">
                                            <Link className="list-group-item-action" to="/services/bookkeeping"
                                            >Bookkeeping</Link>
                                        </li>
                                        <li className="mb-3">
                                            <Link className="list-group-item-action" to="/services"
                                            >Xero</Link>
                                        </li>
                                    </ul> 
                                </div>
                                <div className="col-12 col-sm-4 mt-6 mt-sm-0 navbar-light">
                                    <h5  style={{ marginBottom: '.3 rem' }}>Businesses we Serve </h5>
                                    <div className="mb-3" >
                                    <ul className="list-unstyled mb-0" >
                                        <li style={{ marginBottom: '.1rem' }} >
                                            Tradies
                                        </li>
                                        <li style={{ marginBottom: '.1rem' }}>
                                            Medical professionals
                                        </li>
                                        <li style={{ marginBottom: '.1rem' }}>
                                            Hospitality
                                        </li>
                                        <li style={{ marginBottom: '.1rem' }}>
                                            Contractors
                                        </li>
                                        <li  style={{ marginBottom: '.1rem' }}>
                                            Retail
                                        </li>
                                        <li  style={{ marginBottom: '.1rem' }}>
                                            E-commerce
                                        </li>
                                        <li  style={{ marginBottom: '.1rem' }}>
                                            Real Estate
                                        </li>
                                        <li style={{ marginBottom: '.1rem' }}>
                                            Manufacturing
                                        </li>
                                        

                                    </ul>

                                        
                                    </div>
                                    <div className="mb-3">
                                        
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col">
                            <hr className="m-0" />
                        </div>
                    </div>
                    <div className="row align-items-center mb-5" >
                        <div className="col-md-6" >
                        <p style={{ fontSize: '.8em' }}>
                            <Link className="list-group-item-action" to="https://www.tpb.gov.au/public-register?practitioner=24807880">
                                Seth Arora Pty Ltd is registered with the Australian Tax Practitioners Board TAN 24807880
                            </Link>
                        </p>
                            Copyright ©2024 |  
                            Made by <i className="lar la-heart text-primary heartBeat2"></i> 
                            <Link className="text-primary" to="/"> the D & Y Practice</Link>, Australia
                        </div>
                        
                        <div className="col-md-6 text-md-end mt-3 mt-md-0">
                            <ul className="list-inline mb-0">
                                <li className="me-3 list-inline-item">
                                    
                                </li>
                                <li className="me-3 list-inline-item">
                                    <Link className="list-group-item-action" to="https://blog.dnypractice.com.au/privacy-policy/">
                                    Privacy Policy
                                    </Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link className="list-group-item-action" to="/">  </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            
        </>
    )
}

export default Footer1
