import React from 'react';
import './CaseStudy.css'; // Make sure you also rename the CSS file to match this import
// Import images
import salonImage from '../../../src/assets/images/about/Saloon.png';
import problemImage from '../../../src/assets/images/about/Stress.png';
import happyImage from '../../../src/assets/images/about/Happy.png';


import xeroImage from '../../../src/assets/images/about/02.webp';

const CaseStudy = () => {
  return (
    <div className="caseStudy-container">
      <h1 className="caseStudy-title">Salon Case Study</h1>

      {/* Introduction */}
      <h2 className="caseStudy-subtitle">Introduction</h2>
      <div className="caseStudy-section caseStudy-intro">
        <img src={salonImage} alt="Salon Interior" className="caseStudy-image" />
        <p className="caseStudy-text">We were approached by a small local salon specializing in a range of beauty services. Despite their expertise in beauty treatments, they found themselves tangled in a web of manual accounting processes and outdated desktop software. This was not only consuming valuable time but also leading to discrepancies and compliance risks</p>
      </div>
      <hr /> {/* Separator */}

      {/* Problem Statement */}
      <h2 className="caseStudy-subtitle">Problem Statement</h2>
      <div className="caseStudy-section caseStudy-problem">
        <img src={problemImage} alt="Problem Scenario" className="caseStudy-image" />
        <p className="caseStudy-text">The Business owner Salon was primarily relying on a desktop-based accounting software that was updated every quarter. The lack of real-time updates made it challenging to make quick financial decisions. Additionally, the salon was burdened with a plethora of paper invoices and receipts, making it difficult to keep track of expenses and revenue accurately. These challenges were hampering growth, increasing the risk of non-compliance with tax regulations, and diverting attention from core business activities</p>
      </div>
      <hr /> {/* Separator */}

      {/* Solution */}
      <h2 className="caseStudy-subtitle">Solution</h2>
      <div className="caseStudy-section caseStudy-intro">
        <img src={xeroImage} alt="Salon Interior" className="caseStudy-image" />
        <p className="caseStudy-text">We introduced the business owner to  <strong> Xero </strong>, a cloud-based accounting software that allows for real-time financial tracking. To manage their plethora of paper invoices, <strong>Hubdoc </strong>was implemented for efficient receipt management. Our team provided comprehensive training to ensure the business owner were comfortable using these new systems. In addition, we offered fixed-price bookkeeping and accounting services, guaranteeing transparency and predictability in costs. As a result, their accounts were consistently managed on time, eliminating the risk of late fees and compliance issues</p>
      </div>
      <hr /> {/* Separator */}

      {/* Conclusion */}
      <h2 className="caseStudy-subtitle">Conclusion</h2>
      <div className="caseStudy-section caseStudy-problem">
  <img src={happyImage} alt="Problem Scenario" className="caseStudy-image" />
  <div className="caseStudy-content">
    <p className="caseStudy-text">
      Our work with the business owner exemplifies how modern accounting solutions can revolutionize small business operations. By transitioning to cloud-based accounting and utilizing fixed-price services, the salon not only saved time and money but also achieved greater financial clarity. This has set them on a path for sustainable growth and profitability, ensuring that they can continue to focus on what they do best—making their clients look and feel fabulous
    </p>
    <a href="https://koalendar.com/e/meet-with-david-seth" target="_blank" rel="noopener noreferrer">
    <button className="btn btn-primary">Book a free Consultation</button>
    </a>
  </div>
</div>


    </div>
  );
};

export default CaseStudy;
