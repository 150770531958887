import React from 'react'

function Herosection1() {
  const gototop=()=>{
    window.scrollTo({top:0,behavior:"smooth"})
}
  return (
    <>
      <section className="hero-banner position-relative custom-py-1 hero-shape1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 col-xl-6 order-lg-1 mb-8 mb-lg-0">
              {/* <!-- Image --> */}
              <img
                src={require("../../assets/images/hero/03.jpg")}
                className="img-fluid"
                alt="..."
              />
            </div>
            <div className="col-12 col-lg-7 col-xl-6">
              <h3 className="mb-5">A Specialized Accountant Medical Professionals Can Trust</h3>

              {/* <!-- Buttons --> */}
              <a href="/calendar" target="_blank" rel="noopener noreferrer">
              <div className="btn btn-primary">Book a Free Consultation</div>
              </a>

              <blockquote className="mt-5 mb-0 ps-3 border-start border-primary">
                {/* <!-- Text --> */}
                <p className="lead mb-0">
                Delivering Precise, Streamlined, and Customized Accounting & Tax Solutions for Healthcare Practices
.
                </p>
              </blockquote>
            </div>
          </div>
          {/* <!-- / .row --> */}
        </div>
        {/* <!-- / .container --> */}
      </section>
    </>
  )
}

export default Herosection1
