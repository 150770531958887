
const CapitalGainsTaxCalculator = () => {
    const iframeStyle = {
      display: 'block',
      margin: 'auto',
      maxWidth: '100%',
      border: 'none',
    };
  
    const containerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'row',
      '@media (max-width: 768px)': {
        flexDirection: 'column',
      },
    };
  
    const columnStyle = {
      flex: 1,
      padding: '0 20px',
    };
  
    return (
      <div style={containerStyle}>
        <div style={columnStyle}>
          <div style={{ textAlign: 'center' }}>
            <h4 style={{ paddingBottom: '40px' }}>TAX CALCULATOR 2023</h4>
            <iframe 
              style={iframeStyle}
              width="600" 
              height="900" 
              frameBorder="0" 
              scrolling="no" 
              src="https://onedrive.live.com/embed?resid=5E195D0B1DF2837F%2113694&authkey=%21AEnHOYI_vsXtkdQ&em=2&wdAllowInteractivity=False&ActiveCell='2022-23%20Tax%20Calculator'!A2&Item=calc&wdHideGridlines=True&wdInConfigurator=True&wdInConfigurator=True">
            </iframe>
          </div>
        </div>
        <div style={columnStyle}>
          <h2>Your Header Here</h2>
          <p>Your paragraph here.</p>
        </div>
      </div>
    );
  };
  
  export default CapitalGainsTaxCalculator;
  