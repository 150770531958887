import React from 'react';
import './Herosection1.css';

function Herosection1() {
  return (
    <section className="hero-banner position-relative custom-py-1 hero-shape1">
      <div className="container">
        <div className="row align-items-center hero-content">
          <div className="col-12 col-lg-5 col-xl-6 order-lg-1 mb-8 mb-lg-0">
            <img
              src={require("../../assets/images/hero/01.webp")}
              className="img-fluid hero-image"
              alt="..."
            />
          </div>
          <div className="col-12 col-lg-7 col-xl-6 hero-text">
            <h1 className="mb-5">Tech-Focused Accounting with a Personal Touch</h1>
            <a href="/calendar" target="_blank" rel="noopener noreferrer">
              <div className="btn btn-primary">Book a Free Consultation</div>
            </a>
            <blockquote className="mt-5 mb-0 ps-3 border-start border-primary">
              <p className="lead mb-0">
                Providing Reliable, Efficient, and Tailored Accounting & Tax Solutions for Your Business Needs.
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Herosection1; 