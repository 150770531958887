import React from 'react'
import HerosectionL3 from '../../../Componet/Herosection/HerosectionL3'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import CapitalGrowthCalculator from '../../../Componet/Blog/Calculators/CapitalGrowthCalculator'
import { Helmet } from 'react-helmet'

function CapitalGrowth() {
    return (
        <>
        <Helmet>
            <title>Capital Growth Calculator | Maximize Your Investments | DNY Practice</title>
            <meta name="description" content="Calculate the potential capital growth of your investments with our user-friendly tool. Ideal for investors looking to maximize returns. Try it now at DNY Practice." />
            <meta name="keywords" content="Capital Growth Calculator, Investment Growth, Financial Planning, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calculator/capitalgrowth" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Capital Growth Calculator | Maximize Your Investments" />
            <meta property="og:description" content="Calculate the potential growth of your investments with our tool." />
            <meta property="og:url" content="https://dnypractice.com.au/calculator/capitalgrowth" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionL3 />
            <div className="page-content">
                <CapitalGrowthCalculator />
                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default CapitalGrowth
