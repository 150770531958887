import React, { useState } from 'react';
import './CarLoanCalculator.css'; 


const CapitalGrowthCalculator = () => {
  const [rate, setRate] = useState('');
  const [period, setPeriod] = useState('');
  const [principal, setPrincipal] = useState('');
  const [finalAmount, setFinalAmount] = useState(null);

  const calculateGrowth = () => {
    const annualRate = parseFloat(rate) / 100;
    const numberOfYears = parseFloat(period);
    const initialPrincipal = parseFloat(principal);

    const compoundAmount = initialPrincipal * Math.pow((1 + annualRate), numberOfYears);

    setFinalAmount(compoundAmount.toFixed(2));
  };

  return (
    <div className="calculator-container text-centre">
      <h3>Capital Growth Calculator</h3>
      <form className="calculator-form">
        <label>
          Expected Growth Rate (%):
          <input type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
        </label>
        <label>
          Term (years):
          <input type="number" value={period} onChange={(e) => setPeriod(e.target.value)} />
        </label>
        <label>
          Starting Principal Amount ($):
          <input type="number" value={principal} onChange={(e) => setPrincipal(e.target.value)} />
        </label>
        
        <button type="button" className="btn btn-primary" onClick={calculateGrowth}>
          Calculate
        </button>
      </form>
      {finalAmount && (
        <div className="monthly-payment-container">
          <p><strong>Amount at Maturity: </strong> <span className="monthly-payment-amount">${finalAmount}</span></p>
          <div style={{ maxWidth: '450px', textAlign: 'center', paddingTop: '20px', backgroundColor: '#f8f8f8', border: '1px solid #ddd', margin: 'auto', padding: '15px', fontSize: '16px', color: '#666', lineHeight: '1.5' }}>
             <p><strong>Disclaimer:</strong> The information provided by this calculator is for illustrative purposes only. Be sure to consult a financial professional prior to relying on the results.</p>
            </div>
        </div>
        )}
    </div>
  );
};

export default CapitalGrowthCalculator;
