// src/Components/ClosureBanner.js
import React, { useEffect, useState } from 'react';
import './ClosureBanner.css';

const ClosureBanner = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 11, 20); // Start of closure
    const end = new Date(now.getMonth() === 11 ? now.getFullYear() + 1 : now.getFullYear(), 0, 8); // End of closure

    if (now >= start && now <= end) {
      setShow(true);
      document.body.classList.add('with-banner'); // Add the class to the body

    }
  }, []);

  if (!show) return null;

  return (
    <div className="closure-banner">
      <p><strong>Please Note:</strong> We are closed from Dec 21, 2023 to Jan 7, 2024. We will reopen on Jan 8. Happy Holidays!</p>
    </div>
  );
};

export default ClosureBanner;