import React from 'react';
import { Link } from 'react-router-dom';
import WordSearch from '../../../Componet/Blog/WordSearch.js';
import './NotFoundPage.css';
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'

const NotFoundPage = () => {
  return (
    <div 
      className="not-found-page"
      
    >
      <h5> Page Not found</h5>
      <p>While you're here, enjoy a quick game or you can go back to the <Link to="/"> Home Page</Link></p>
      <WordSearch />
      <NewsletterL1 />

    </div>
  );
};

export default NotFoundPage;
