import React, { useEffect, useState } from 'react';
import './Popup.css';
import myImage from '../../assets/images/app/onlinemeeting1.webp';  // Adjust the path according to your folder structure


const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isPopupShown = sessionStorage.getItem('popupShown');

    if (!isPopupShown) {
      setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem('popupShown', 'true');
      }, 50000);
    }
  }, []);

  const handleClose = () => {

    setShowPopup(false);  // Hide the popup
  };



  return (
    <>

      {showPopup && <div className={`fade-background ${showPopup ? 'active' : ''}`}></div>}
        {showPopup && (
          <div className="popup">
            <div className="image-wrapper">
              <div className="image-container" style={{ backgroundImage: `url(${myImage})` }}></div>
              </div>
              <div className="content-container">


            
                <button className="close-button" onClick={handleClose}>X</button>

                <h1 className="headline">Get a Free Consultation</h1>
                <p className="description">
                <strong>1. Expert Advice:</strong> Gain valuable insights from certified accounting professionals. <br />
                <strong>2. Save Time and Money:</strong>  Learn how to streamline your accounting processes and save on taxes. <br />
                <strong>3. No Obligation:</strong> It's a no-strings-attached offer. You're not obligated to use our services afterward. <br />  
                </p>


        {/*<form>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <input type="tel" placeholder="Phone Number" />
      </form> */}
              <div className="divider"></div>
                <button className="btn btn-primary"  onClick={() => window.open('https://koalendar.com/e/meet-with-dny', '_blank')}>Book Free Consultation</button>
                <div style={{ textAlign: 'center' }}>
                <small>We don't spam</small>
                </div>
              </div>
          </div>
    )}
    </> 
  );  
    };

export default Popup;
