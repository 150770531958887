import React from 'react'
import HerosectionHeader from '../../../Componet/Herosection/HerosectionHeader'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import FBT from '../../../Componet/Blog/Calculators/FBT'
import { Helmet } from 'react-helmet'

function Equity1() {
    return (
        <>
        <Helmet>
                <title>Novated Leasing Calculator |  DNY Practice</title>
                <meta name="description" content="Calculate Tax savings by Salary Packaging, Novated Leasing Electric cars, Melbourne, DNY Practice" />
                <meta name="keywords" content="Salary Packaging, Novated Leasing, Electric cars, Melbourne, DNY Practice, Small Business Accountants" />
                <link rel="canonical" href="https://dnypractice.com.au/calculator/FBT" />
                <meta name="language" content="English" />
                <meta name="country" content="Australia" />
                <meta property="og:title" content="FBT Calculator | Optimize Your Investments" />
                <meta property="og:description" content="Understand the Tax Savings for A Novated Lease car" />
                <meta property="og:url" content="https://dnypractice.com.au/calculator/FBT" />
                <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionHeader name="Novated Leasing Calculator" />
            <div className="page-content">
                <FBT />

                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default Equity1
