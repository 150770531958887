import React, { useState } from 'react';
import './CarLoanCalculator.css'; 

const CarLoanCalculator = () => {
  const [rate, setRate] = useState('');
  const [period, setPeriod] = useState('');
  const [principal, setPrincipal] = useState('');
  const [balloon, setBalloon] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);

  const calculateMonthlyPayment = () => {
    const monthlyRate = rate / 12 / 100;
    const numberOfPayments = period * 12;
    const balloonPayment = balloon || 0;

    const monthlyPayment = -(
      (principal - balloonPayment / Math.pow(1 + monthlyRate, numberOfPayments)) *
      (monthlyRate / (1 - Math.pow(1 + monthlyRate, -numberOfPayments)))
    );

    setMonthlyPayment(monthlyPayment.toFixed(2));
  };

  return (
    <div className="calculator-container text-centre">
      <form className="calculator-form">
        <label>
          Interest Rate (%):
          <input type="number" value={rate} onChange={(e) => setRate(e.target.value)} />
        </label>
        <label>
          Loan Period (years):
          <input type="number" value={period} onChange={(e) => setPeriod(e.target.value)} />
        </label>
        <label>
          Principal Amount:
          <input type="number" value={principal} onChange={(e) => setPrincipal(e.target.value)} />
        </label>
        <label>
          Balloon Payment:
          <input type="number" value={balloon} onChange={(e) => setBalloon(e.target.value)} />
        </label>
        <button type="button" className="btn btn-primary" onClick={calculateMonthlyPayment}>
          Calculate
        </button>
      </form>
      {monthlyPayment && (
        <div className="monthly-payment-container">
          <p><strong>Monthly Payment for Your Vehicle:</strong> <span className="monthly-payment-amount">${monthlyPayment}</span></p>
          <div style={{ maxWidth: '450px', textAlign: 'center', paddingTop: '20px', backgroundColor: '#f8f8f8', border: '1px solid #ddd', margin: 'auto', padding: '15px', fontSize: '16px', color: '#666', lineHeight: '1.5' }}>
             <p><strong>Disclaimer:</strong> The information provided by this calculator is for illustrative purposes only. Be sure to consult a financial professional prior to relying on the results.</p>
            </div>
        </div>
        )}
        
    </div>
  );
};

export default CarLoanCalculator;
