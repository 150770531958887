import React from 'react'
import HerosectionL3 from '../../../Componet/Herosection/HerosectionL3'
import NewsletterL1 from '../../../Componet/Newsletter/NewsletterL1'
import BlogL5 from '../../../Componet/Blog/BlogL5'
import CompoundingCalculator from '../../../Componet/Blog/Calculators/CompoundingCalculator'
import { Helmet } from 'react-helmet'

function CapitalGrowth() {
    return (
        <>
        <Helmet>
            <title>Compounding Interest Calculator | Maximize Your Investments | DNY Practice</title>
            <meta name="description" content="Discover the power of your monthly investments with our accurate compounding interest calculator. Perfect for strategizing your financial growth and investment planning. Available now at ABCPractice for savvy wealth builders."/>
            <meta name="keywords" content="Compounding Interest calculator, Investment Growth, Melbourne, DNY Practice" />
            <link rel="canonical" href="https://dnypractice.com.au/calculator/compoundingcalculator" />
            <meta name="language" content="English" />
            <meta name="country" content="Australia" />
            <meta property="og:title" content="Compounding Interest calculator| Maximize Your Investments" />
            <meta property="og:description" content="Calculate the potential growth of your investments with this tool." />
            <meta property="og:url" content="https://dnypractice.com.au/calculator/compoundingcalculator" />
            <meta property="og:type" content="website" />
        </Helmet>

            <HerosectionL3 />
            <div className="page-content">
                <CompoundingCalculator />
                <NewsletterL1 />
                <BlogL5 />
                

                
            </div>
        </>
    )
}

export default CapitalGrowth
