

const Kalendar = () => {
  const iframeStyle = {
    display: 'block',
    margin: 'auto',
    maxWidth: '100%',
    border: 'none',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  };

  const columnStyle = {
    flex: 1,
    padding: '0 20px',
  };

  return (
    <div className="calendarr">
    <div style={containerStyle}>
      <div style={columnStyle}>
        <div style={{ textAlign: 'center', paddingBottom: 20 }}>
          <iframe 
            style={iframeStyle}
            width="900" 
            height="700" 
            frameBorder="0" 
            scrolling="no" 
            src="https://koalendar.com/e/meet-with-dny">
          </iframe>
            
          

          </div>
        </div>
      
      
      
      </div>

    </div>
      
      

      
  );
};

export default Kalendar;


