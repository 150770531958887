import React from 'react'
import BlogListing1Comp from './BlogListing1Comp'
import NewsletterL1 from '../../Componet/Newsletter/NewsletterL1'
import HerosectionHeader from '../../Componet/Herosection/HerosectionHeader'
import CaseStudy from '../../Componet/CaseStudy/CaseStudy'
import BlogL6 from '../../Componet/Blog/BlogL6'




const CaseStudy1 = () => {
    return (
        <>
            <div class="page-content">
                <CaseStudy />
                <NewsletterL1 />
                <BlogL6 />

            </div>
            </>
        
    )
}

export default CaseStudy1
