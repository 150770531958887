import React, { useState } from 'react';
import './CarLoanCalculator.css'; 

const MaturityAmountCalculator = () => {
  const [monthlyPayment, setMonthlyPayment] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [term, setTerm] = useState('');
  const [principal, setPrincipal] = useState('');
  const [maturityAmount, setMaturityAmount] = useState(null);

  const calculateMaturityAmount = () => {
    const monthlyRate = parseFloat(interestRate) / 100 / 12;
    const totalMonths = parseFloat(term) * 12;
    let amount = parseFloat(principal);

    for (let i = 0; i < totalMonths; i++) {
      amount += parseFloat(monthlyPayment);
      amount *= 1 + monthlyRate;
    }

    setMaturityAmount(amount.toFixed(2));
  };

  return (
    <div className="calculator-and-blog-container">

    <div className="calculator-container text-centre">
      <h3>Compound interest calculator</h3>
      <form className="calculator-form">
        <label>
          Starting Principal Amount ($):
          <input type="number" value={principal} onChange={(e) => setPrincipal(e.target.value)} />
        </label>
        <label>
          Monthly Payment ($):
          <input type="number" value={monthlyPayment} onChange={(e) => setMonthlyPayment(e.target.value)} />
        </label>
        <label>
          Annual Interest Rate (%):
          <input type="number" value={interestRate} onChange={(e) => setInterestRate(e.target.value)} />
        </label>
        <label>
          Term (years):
          <input type="number" value={term} onChange={(e) => setTerm(e.target.value)} />
        </label>
        
        <button type="button" className="btn btn-primary" onClick={calculateMaturityAmount}>
          Calculate
        </button>
      </form>
      {maturityAmount && (
        <div className="maturity-amount-container">
          <p><strong>Amount at Maturity: </strong> <span className="maturity-amount">${maturityAmount}</span></p>
          <div style={{ maxWidth: '450px', textAlign: 'center', paddingTop: '20px', backgroundColor: '#f8f8f8', border: '1px solid #ddd', margin: 'auto', padding: '15px', fontSize: '16px', color: '#666', lineHeight: '1.5' }}>
             <p><strong>Disclaimer:</strong> The information provided by this calculator is for illustrative purposes only. Be sure to consult a financial professional prior to relying on the results.</p>
            </div>
        </div>
        
      )}
      </div>
       
        <div className="blog-container">
        <h3>Compound Interest: The Silent Force in Wealth Accumulation</h3>
        <p>Often referred to as the "eighth wonder of the world," compound interest is a powerful force in finance, capable of transforming modest savings into substantial wealth over time. It's the process where the interest you earn on an investment is reinvested, and this new total balance then earns additional interest. This cycle of earning interest on interest is what sets compound interest apart from simple interest, where you earn interest solely on the initial principal.</p>
        <h4>The Power of Starting Small</h4>
        <p>One of the most remarkable aspects of compound interest is its ability to magnify small, regular investments into significant sums. This is particularly relevant for individuals who may not have large amounts to invest initially. By consistently investing even small amounts, the power of compounding starts to take effect, gradually and steadily building wealth.</p>
        <h4>The Magic of Time and Regular Contributions</h4>
        <p>The key to harnessing the full potential of compound interest lies in time and regular contributions. The earlier you start, the more time your money has to grow. Regular contributions, no matter how modest, can have a profound impact over the long term. This is due to the exponential nature of compound interest, where the growth rate accelerates over time.</p>
        <h4>Using a Compound Interest Calculator</h4>
        <p>To truly appreciate how small contributions can add up, using a compound interest calculator is invaluable. These calculators allow you to input different variables, such as the monthly contribution amount, interest rate, and investment duration, to project the future value of your savings. This can be an eye-opening experience, showing just how achievable financial security can be with patience and consistent effort.</p>
      </div>

    </div>
  );
};

export default MaturityAmountCalculator;
