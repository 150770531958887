import React, { useState } from 'react';
import './MortgageRepaymentCalculator.css';

const TaxCalculator = () => {
  const [income, setIncome] = useState('');
  const [expenses, setExpenses] = useState('');
  const [taxableIncome, setTaxableIncome] = useState(null);
  const [currentTax, setCurrentTax] = useState(null);
  const [newTax, setNewTax] = useState(null);
  const [taxDifference, setTaxDifference] = useState(null);

  const calculateTax = () => {
    const incomeValue = parseFloat(income);
    const expensesValue = parseFloat(expenses);
    const taxableIncomeValue = incomeValue - expensesValue;

    let currentTaxValue;
    let newTaxValue;

    // Current tax calculation logic
    if (taxableIncomeValue <= 18200) {
      currentTaxValue = 0;
    } else if (taxableIncomeValue <= 45000) {
      currentTaxValue = 0.19 * (taxableIncomeValue - 18200);
    } else if (taxableIncomeValue <= 120000) {
      currentTaxValue = 5092 + 0.325 * (taxableIncomeValue - 45000);
    } else if (taxableIncomeValue <= 180000) {
      currentTaxValue = 29467 + 0.37 * (taxableIncomeValue - 120000);
    } else {
      currentTaxValue = 51667 + 0.45 * (taxableIncomeValue - 180000);
    }

    // New tax calculation with updated Stage 3 tax cuts
    if (taxableIncomeValue <= 18200) {
      newTaxValue = 0;
    } else if (taxableIncomeValue <= 45000) {
      newTaxValue = 0.16 * (taxableIncomeValue - 18200);
    } else if (taxableIncomeValue <= 135000) {
      newTaxValue = 4288 + 0.30 * (taxableIncomeValue - 45000);
    } else if (taxableIncomeValue <= 190000) {
      newTaxValue = 31288 + 0.37 * (taxableIncomeValue - 135000);
    } else {
      newTaxValue = 51638 + 0.45 * (taxableIncomeValue - 190000);
    }






    const taxDifferenceValue = currentTaxValue - newTaxValue;

    setTaxableIncome(taxableIncomeValue.toFixed(2));
    setCurrentTax(currentTaxValue.toFixed(2));
    setNewTax(newTaxValue.toFixed(2));
    setTaxDifference(taxDifferenceValue.toFixed(2));
  };

  return (
    <div className="calculator-container">
      <h3>Tax Calculator</h3>
      <p>Calculate your tax payable before and after the Stage 3 tax cuts. <a href="https://blog.dnypractice.com.au/Stage-3-tax-cuts-updated" target="_blank" rel="noopener noreferrer">Blog for more information</a></p>
      <form className="calculator-form">
        <label>
          Income ($):
          <input type="number" value={income} onChange={(e) => setIncome(e.target.value)} />
        </label>
        <label>
          Expenses ($):
          <input type="number" value={expenses} onChange={(e) => setExpenses(e.target.value)} />
        </label>
        
        <button type="button" className="btn btn-primary" onClick={calculateTax}>
          Calculate
        </button>
      </form>
      {taxableIncome && (
        <div className="tax-calculation-results">
          <p>Taxable Income: ${taxableIncome}</p>
          <p className="tax-savings">Current Tax Payable: ${currentTax}</p>
          <p className="tax-savings-highlight">Tax Payable After Stage 3 Tax Cuts: ${newTax}</p>
          <p className="tax-savings-highlight">Tax Savings Due to Stage 3 Tax Cuts: ${taxDifference}</p>
          <div className="disclaimer">
            <p><strong>Disclaimer:</strong> The Calculator is intended as a guide only. Any advice contained in this calculator is of a general nature and does not take into account the personal needs and circumstances of any particular individual.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TaxCalculator;
