import React from 'react';
import './AddOnsTable.css'; // Make sure to create a corresponding CSS file

const addOnsData = [
  
  {
    description: "Individual tax return",
    detail: "The yearly exercise !!",
    price: "$199/year",
    note: ""
  },
  {
    description: "Tax Planning with a Business Partner",
    detail: "Receive specialist tax advice from our tax experts",
    price: "$299/hour",
    note: ""
  },
  {
    description: "Rental Properties",
    detail: "Renting one or more properties? We’ll help you process your tax!",
    price: "$79/year",
    note: "(Per property)"
  },
  {
    description: "Company tax return",
    detail: "The yearly exercise!!",
    price: "$1,499/year",
    note: " "
  },
  {
    description: "Company formation",
    detail: "We’ll help you set up your company",
    price: "$999 + GST",
    note: " "
  },
  {
    description: " Trust formation with a Trustee company",
    detail: "We’ll help you set up your trust",
    price: "$1399 + GST",
    note: " "
  },
  {
    description: "ASIC Compliance",
    detail: "We’ll help you meet your ASIC obligations",
    price: "$250/year",
    note: " "
  },
  {
    description: "Capital Gains Tax on sale of property",
    detail: "We’ll help you calculate your capital gains tax on the sale of an property",
    price: "$199/event",
    note: " "
  },
  {
    description: "Capital Gains Tax on sale of shares",
    detail: "We’ll help you calculate your capital gains tax on the sale of shares",
    price: "$20/event",
    note: " "
  }

];

const AddOnsTable = () => {
  return (
    <div className="addons-table">
      <h1 style={{textAlign: "center"}}>Add Ons</h1>
      <div className="addons-header">
        <div className="header-description">DESCRIPTION</div>
        <div className="header-price">PRICE</div>
      </div>
      {addOnsData.map((addOn, index) => (
        <div key={index} className="addon-row">
          <div className="addon-description">
            <strong>{addOn.description}</strong>
            <p>{addOn.detail}</p>
          </div>
          <div className="addon-price">
            {addOn.price}
            <span className="addon-note">{addOn.note}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddOnsTable;
