import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const features = [
  {
    icon: 'calculator.png',  // Changed from 'flaticon-calculator'
    iconType: 'png',  // New field to specify the icon type
    background: '#d0faec',
    title: 'Tax Planning',
    description: 'We offer strategic tax planning to minimize your liability and maximize savings.',
    attribution: 'Calculator icons created by Pixel perfect - Flaticon',  // New field for attribution
    attributionLink: 'https://www.flaticon.com/free-icons/calculator'  // New field for attribution link
  },
  
  {
    icon: 'compliance.png',
    iconType: 'png',
    background: '#ffeff8',
    title: 'Compliance',
    description: 'We ensure your financials are compliant with legal requirements, avoiding penalties.', 
    attribution: 'Calculator icons created by Uniconlabs - Flaticon',  // New field for attribution
    attributionLink: 'https://www.flaticon.com/free-icons/compliance',
    
  },
  {
    icon: 'financial-advisor.png',
    iconType: 'png',
    background: '#d3f6fe',
    title: 'Financial Advisory',
    description: 'Our expert advice helps you make informed financial decisions for long-term success.',
    attribution: 'Calculator icons created by Surang - Flaticon',  // New field for attribution
    attributionLink: 'https://www.flaticon.com/free-icons/financial-advisor'
  },
  {
    icon: 'cloud-accounting.png',
    iconType: 'png',
    background: '#fff5d9',
    title: 'Cloud Accounting',
    description: 'Get real-time access to your financial data, securely stored in the cloud.',
    attribution: 'Calculator icons created by Graphixs Art - Flaticon',  // New field for attribution
    attributionLink: 'https://www.flaticon.com/free-icons/cloud-accounting'
  },
  {
    icon: 'flaticon-relationship',
    background: '#fdf9ee',
    title: 'Client-Centric',
    description: 'We focus on your unique needs, offering tailored solutions for your business'
  },
  {
    icon: 'flaticon-call-center-1',
    background: '#e5f5f5',
    title: 'Quick Support',
    description: 'Our tax experts are always ready to assist you with any queries.'
  }
];


function FeatureL2() {
  return (
    <section>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="8">
            <div className="mb-5">
              <h2 className="mb-0">
                <span className="font-w-4 d-block">Tailored Solutions</span> for Your Accounting Needs
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          {features.map((feature, index) => (
            <Col lg="4" md="6" key={index} className="mt-5">
              <div className="d-flex justify-content-between">
                <div className="me-3">
                  <div className="f-icon-s p-3 rounded" style={{ background: feature.background }}>
                  {feature.iconType === 'png' ? (
                      <img src={require(`../../assets/icon/${feature.icon}`)} alt={feature.title} style={{ width: '36px', height: '36px' }}/>
                    ) : (
                    <i className={`${feature.icon}`}></i>)}
                    <img src={`../../assets/images/client/${index + 9}.png`} alt="" />
                  </div>
                </div>
                <div>
                  <h5 className="mb-2">{feature.title}</h5>
                  <p className="mb-0">{feature.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default FeatureL2;



{ /* const features = [
  {icon: 'flaticon-dashboard', background: "#d0faec", title: "Dashboard", description: "Taking design from winck design and typography layouts." },
  {icon: 'flaticon-relationship', background: "#ffeff8", title: "Management", description: "Taking design from winck design and typography layouts." },
  {icon: 'flaticon-solution' ,background: "#d3f6fe", title: "Platform", description: "Taking design from winck design and typography layouts." },
  {icon: 'flaticon-system', background: "#fff5d9", title: "Integrations", description: "Taking design from winck design and typography layouts." },
  {icon: 'flaticon-friendship', background: "#fdf9ee", title: "User Friendly", description: "Taking design from winck design and typography layouts." },
  {icon: 'flaticon-call-center-1', background: "#e5f5f5", title: "Quick Support", description: "Taking design from winck design and typography layouts." },
]; */}